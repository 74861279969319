html body {
  background-color: #f8fafb;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
  -webkit-transition-delay: 9999s;
}

body:before, body:after, #wrapper:before, #wrapper:after {
  display: none !important;
}

button {
  outline: none !important;
}

.btn, .form-control {
  transition: all 0.2s ease-in-out;
}

.btn.btn-default > i.fa-sort-down {
  position: relative;
  top: -2px;
  margin-left: 5px;
}

select.form-control {
  cursor: pointer;
}

.mt-1 {
  margin-top: 5px;
}

.mb-1 {
  margin-bottom: 5px;
}

.mr-1 {
  margin-right: 5px;
}

.ml-1 {
  margin-left: 5px;
}

.pt-1 {
  padding-top: 5px;
}

.pb-1 {
  padding-bottom: 5px;
}

.pr-1 {
  padding-right: 5px;
}

.pl-1 {
  padding-left: 5px;
}

.mt-2 {
  margin-top: 10px;
}

.mb-2 {
  margin-bottom: 10px;
}

.mr-2 {
  margin-right: 10px;
}

.ml-2 {
  margin-left: 10px;
}

.pt-2 {
  padding-top: 10px;
}

.pb-2 {
  padding-bottom: 10px;
}

.pr-2 {
  padding-right: 10px;
}

.pl-2 {
  padding-left: 10px;
}

.mt-3 {
  margin-top: 15px;
}

.mb-3 {
  margin-bottom: 15px;
}

.mr-3 {
  margin-right: 15px;
}

.ml-3 {
  margin-left: 15px;
}

.pt-3 {
  padding-top: 15px;
}

.pb-3 {
  padding-bottom: 15px;
}

.pr-3 {
  padding-right: 15px;
}

.pl-3 {
  padding-left: 15px;
}

.mt-4 {
  margin-top: 20px;
}

.mb-4 {
  margin-bottom: 20px;
}

.mr-4 {
  margin-right: 20px;
}

.ml-4 {
  margin-left: 20px;
}

.pt-4 {
  padding-top: 20px;
}

.pb-4 {
  padding-bottom: 20px;
}

.pr-4 {
  padding-right: 20px;
}

.pl-4 {
  padding-left: 20px;
}

.mt-5 {
  margin-top: 25px;
}

.mb-5 {
  margin-bottom: 25px;
}

.mr-5 {
  margin-right: 25px;
}

.ml-5 {
  margin-left: 25px;
}

.pt-5 {
  padding-top: 25px;
}

.pb-5 {
  padding-bottom: 25px;
}

.pr-5 {
  padding-right: 25px;
}

.pl-5 {
  padding-left: 25px;
}

.mt-6 {
  margin-top: 30px;
}

.mb-6 {
  margin-bottom: 30px;
}

.mr-6 {
  margin-right: 30px;
}

.ml-6 {
  margin-left: 30px;
}

.pt-6 {
  padding-top: 30px;
}

.pb-6 {
  padding-bottom: 30px;
}

.pr-6 {
  padding-right: 30px;
}

.pl-6 {
  padding-left: 30px;
}

.mt-7 {
  margin-top: 35px;
}

.mb-7 {
  margin-bottom: 35px;
}

.mr-7 {
  margin-right: 35px;
}

.ml-7 {
  margin-left: 35px;
}

.pt-7 {
  padding-top: 35px;
}

.pb-7 {
  padding-bottom: 35px;
}

.pr-7 {
  padding-right: 35px;
}

.pl-7 {
  padding-left: 35px;
}

.mt-8 {
  margin-top: 40px;
}

.mb-8 {
  margin-bottom: 40px;
}

.mr-8 {
  margin-right: 40px;
}

.ml-8 {
  margin-left: 40px;
}

.pt-8 {
  padding-top: 40px;
}

.pb-8 {
  padding-bottom: 40px;
}

.pr-8 {
  padding-right: 40px;
}

.pl-8 {
  padding-left: 40px;
}

.mt-9 {
  margin-top: 45px;
}

.mb-9 {
  margin-bottom: 45px;
}

.mr-9 {
  margin-right: 45px;
}

.ml-9 {
  margin-left: 45px;
}

.pt-9 {
  padding-top: 45px;
}

.pb-9 {
  padding-bottom: 45px;
}

.pr-9 {
  padding-right: 45px;
}

.pl-9 {
  padding-left: 45px;
}

.mt-10 {
  margin-top: 50px;
}

.mb-10 {
  margin-bottom: 50px;
}

.mr-10 {
  margin-right: 50px;
}

.ml-10 {
  margin-left: 50px;
}

.pt-10 {
  padding-top: 50px;
}

.pb-10 {
  padding-bottom: 50px;
}

.pr-10 {
  padding-right: 50px;
}

.pl-10 {
  padding-left: 50px;
}

.mt-11 {
  margin-top: 55px;
}

.mb-11 {
  margin-bottom: 55px;
}

.mr-11 {
  margin-right: 55px;
}

.ml-11 {
  margin-left: 55px;
}

.pt-11 {
  padding-top: 55px;
}

.pb-11 {
  padding-bottom: 55px;
}

.pr-11 {
  padding-right: 55px;
}

.pl-11 {
  padding-left: 55px;
}

.mt-12 {
  margin-top: 60px;
}

.mb-12 {
  margin-bottom: 60px;
}

.mr-12 {
  margin-right: 60px;
}

.ml-12 {
  margin-left: 60px;
}

.pt-12 {
  padding-top: 60px;
}

.pb-12 {
  padding-bottom: 60px;
}

.pr-12 {
  padding-right: 60px;
}

.pl-12 {
  padding-left: 60px;
}

.hide-control {
  display: none !important;
}

.dv-placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentColor;
  opacity: 0.5;
}

.dv-placeholder.btn::before {
  display: inline-block;
  content: "";
}

.dv-placeholder-xs {
  min-height: 0.6em;
}

.dv-placeholder-sm {
  min-height: 0.8em;
}

.dv-placeholder-lg {
  min-height: 1.2em;
}

.dv-placeholder-glow .dv-placeholder {
  animation: dv-placeholder-glow 2s ease-in-out infinite;
}

.show-on-mobile {
  display: none;
}
@keyframes dv-placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.dv-placeholder-wave {
  -webkit-mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  -webkit-mask-size: 200% 100%;
  mask-size: 200% 100%;
  animation: dv-placeholder-wave 1s linear infinite;
}
@keyframes dv-placeholder-wave {
  100% {
    -webkit-mask-position: -200% 0%;
    mask-position: -200% 0%;
  }
}
.dv-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .dv-col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.dv-col-1, .dv-col-2, .dv-col-3, .dv-col-4, .dv-col-5, .dv-col-6, .dv-col-7, .dv-col-8, .dv-col-9, .dv-col-10, .dv-col-11, .dv-col-12, .dv-col,
.dv-col-auto, .dv-col-sm-1, .dv-col-sm-2, .dv-col-sm-3, .dv-col-sm-4, .dv-col-sm-5, .dv-col-sm-6, .dv-col-sm-7, .dv-col-sm-8, .dv-col-sm-9, .dv-col-sm-10, .dv-col-sm-11, .dv-col-sm-12, .dv-col-sm,
.dv-col-sm-auto, .dv-col-md-1, .dv-col-md-2, .dv-col-md-3, .dv-col-md-4, .dv-col-md-5, .dv-col-md-6, .dv-col-md-7, .dv-col-md-8, .dv-col-md-9, .dv-col-md-10, .dv-col-md-11, .dv-col-md-12, .dv-col-md,
.dv-col-md-auto, .dv-col-lg-1, .dv-col-lg-2, .dv-col-lg-3, .dv-col-lg-4, .dv-col-lg-5, .dv-col-lg-6, .dv-col-lg-7, .dv-col-lg-8, .dv-col-lg-9, .dv-col-lg-10, .dv-col-lg-11, .dv-col-lg-12, .dv-col-lg,
.dv-col-lg-auto, .dv-col-xl-1, .dv-col-xl-2, .dv-col-xl-3, .dv-col-xl-4, .dv-col-xl-5, .dv-col-xl-6, .dv-col-xl-7, .dv-col-xl-8, .dv-col-xl-9, .dv-col-xl-10, .dv-col-xl-11, .dv-col-xl-12, .dv-col-xl, .dv-col-xxl-1, .dv-col-xxl-2, .dv-col-xxl-3, .dv-col-xxl-4, .dv-col-xxl-5, .dv-col-xxl-6, .dv-col-xxl-7, .dv-col-xxl-8, .dv-col-xxl-9, .dv-col-xxl-10, .dv-col-xxl-11, .dv-colxxl-12, .dv-col-xxl,
.dv-col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.dv-col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.dv-col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.dv-col-1 {
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.dv-col-2 {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.dv-col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.dv-col-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.dv-col-5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.dv-col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.dv-col-7 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.dv-col-8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.dv-col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.dv-col-10 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.dv-col-11 {
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.dv-col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.333333%;
}

.offset-2 {
  margin-left: 16.666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.333333%;
}

.offset-5 {
  margin-left: 41.666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.333333%;
}

.offset-8 {
  margin-left: 66.666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.333333%;
}

.offset-11 {
  margin-left: 91.666667%;
}

@media (min-width: 576px) {
  .dv-col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .dv-col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .dv-col-sm-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .dv-col-sm-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .dv-col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .dv-col-sm-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .dv-col-sm-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .dv-col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .dv-col-sm-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .dv-col-sm-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .dv-col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .dv-col-sm-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .dv-col-sm-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .dv-col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.333333%;
  }
  .offset-sm-2 {
    margin-left: 16.666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.333333%;
  }
  .offset-sm-5 {
    margin-left: 41.666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.333333%;
  }
  .offset-sm-8 {
    margin-left: 66.666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.333333%;
  }
  .offset-sm-11 {
    margin-left: 91.666667%;
  }
}
@media (min-width: 768px) {
  .dv-col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .dv-col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .dv-col-md-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .dv-col-md-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .dv-col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .dv-col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .dv-col-md-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .dv-col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .dv-col-md-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .dv-col-md-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .dv-col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .dv-col-md-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .dv-col-md-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .dv-col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.333333%;
  }
  .offset-md-2 {
    margin-left: 16.666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.333333%;
  }
  .offset-md-5 {
    margin-left: 41.666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.333333%;
  }
  .offset-md-8 {
    margin-left: 66.666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.333333%;
  }
  .offset-md-11 {
    margin-left: 91.666667%;
  }
}
@media (min-width: 992px) {
  .dv-col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .dv-col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .dv-col-lg-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .dv-col-lg-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .dv-col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .dv-col-lg-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .dv-col-lg-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .dv-col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .dv-col-lg-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .dv-col-lg-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .dv-col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .dv-col-lg-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .dv-col-lg-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .dv-col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.333333%;
  }
  .offset-lg-2 {
    margin-left: 16.666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.333333%;
  }
  .offset-lg-5 {
    margin-left: 41.666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.333333%;
  }
  .offset-lg-8 {
    margin-left: 66.666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.333333%;
  }
  .offset-lg-11 {
    margin-left: 91.666667%;
  }
}
@media (min-width: 1200px) {
  .dv-col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .dv-col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .dv-col-xl-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .dv-col-xl-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .dv-col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .dv-col-xl-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .dv-col-xl-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .dv-col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .dv-col-xl-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .dv-col-xl-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .dv-col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .dv-col-xl-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .dv-col-xl-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .dv-col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.333333%;
  }
  .offset-xl-2 {
    margin-left: 16.666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.333333%;
  }
  .offset-xl-5 {
    margin-left: 41.666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.333333%;
  }
  .offset-xl-8 {
    margin-left: 66.666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.333333%;
  }
  .offset-xl-11 {
    margin-left: 91.666667%;
  }
}
@media (min-width: 1600px) {
  .dv-col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .dv-col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .dv-col-xxl-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .dv-col-xxl-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .dv-col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .dv-col-xxl-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .dv-col-xxl-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .dv-col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .dv-col-xxl-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .dv-col-xxl-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .dv-col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .dv-col-xxl-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .dv-col-xxl-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .dv-col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xxl-first {
    order: -1;
  }
  .order-xxl-last {
    order: 13;
  }
  .order-xxl-0 {
    order: 0;
  }
  .order-xxl-1 {
    order: 1;
  }
  .order-xxl-2 {
    order: 2;
  }
  .order-xxl-3 {
    order: 3;
  }
  .order-xxl-4 {
    order: 4;
  }
  .order-xxl-5 {
    order: 5;
  }
  .order-xxl-6 {
    order: 6;
  }
  .order-xxl-7 {
    order: 7;
  }
  .order-xxl-8 {
    order: 8;
  }
  .order-xxl-9 {
    order: 9;
  }
  .order-xxl-10 {
    order: 10;
  }
  .order-xxl-11 {
    order: 11;
  }
  .order-xxl-12 {
    order: 12;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.666667%;
  }
}
.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.dv-row-sm {
  margin-left: -7.5px !important;
  margin-right: -7.5px !important;
}
.dv-row-sm .dv-col-1, .dv-row-sm .dv-col-2, .dv-row-sm .dv-col-3, .dv-row-sm .dv-col-4, .dv-row-sm .dv-col-5, .dv-row-sm .dv-col-6, .dv-row-sm .dv-col-7, .dv-row-sm .dv-col-8, .dv-row-sm .dv-col-9, .dv-row-sm .dv-col-10, .dv-row-sm .dv-col-11, .dv-row-sm .dv-col-12, .dv-row-sm .dv-col,
.dv-row-sm .dv-col-auto, .dv-row-sm .dv-col-sm-1, .dv-row-sm .dv-col-sm-2, .dv-row-sm .dv-col-sm-3, .dv-row-sm .dv-col-sm-4, .dv-row-sm .dv-col-sm-5, .dv-row-sm .dv-col-sm-6, .dv-row-sm .dv-col-sm-7, .dv-row-sm .dv-col-sm-8, .dv-row-sm .dv-col-sm-9, .dv-row-sm .dv-col-sm-10, .dv-row-sm .dv-col-sm-11, .dv-row-sm .dv-col-sm-12, .dv-row-sm .dv-col-sm,
.dv-row-sm .dv-col-sm-auto, .dv-row-sm .dv-col-md-1, .dv-row-sm .dv-col-md-2, .dv-row-sm .dv-col-md-3, .dv-row-sm .dv-col-md-4, .dv-row-sm .dv-col-md-5, .dv-row-sm .dv-col-md-6, .dv-row-sm .dv-col-md-7, .dv-row-sm .dv-col-md-8, .dv-row-sm .dv-col-md-9, .dv-row-sm .dv-col-md-10, .dv-row-sm .dv-col-md-11, .dv-row-sm .dv-col-md-12, .dv-row-sm .dv-col-md,
.dv-row-sm .dv-col-md-auto, .dv-row-sm .dv-col-lg-1, .dv-row-sm .dv-col-lg-2, .dv-row-sm .dv-col-lg-3, .dv-row-sm .dv-col-lg-4, .dv-row-sm .dv-col-lg-5, .dv-row-sm .dv-col-lg-6, .dv-row-sm .dv-col-lg-7, .dv-row-sm .dv-col-lg-8, .dv-row-sm .dv-col-lg-9, .dv-row-sm .dv-col-lg-10, .dv-row-sm .dv-col-lg-11, .dv-row-sm .dv-col-lg-12, .dv-row-sm .dv-col-lg,
.dv-row-sm .dv-col-lg-auto, .dv-row-sm .dv-col-xl-1, .dv-row-sm .dv-col-xl-2, .dv-row-sm .dv-col-xl-3, .dv-row-sm .dv-col-xl-4, .dv-row-sm .dv-col-xl-5, .dv-row-sm .dv-col-xl-6, .dv-row-sm .dv-col-xl-7, .dv-row-sm .dv-col-xl-8, .dv-row-sm .dv-col-xl-9, .dv-row-sm .dv-col-xl-10, .dv-row-sm .dv-col-xl-11, .dv-row-sm .dv-col-xl-12, .dv-row-sm .dv-col-xl, .dv-row-sm .dv-col-xxl-1, .dv-row-sm .dv-col-xxl-2, .dv-row-sm .dv-col-xxl-3, .dv-row-sm .dv-col-xxl-4, .dv-row-sm .dv-col-xxl-5, .dv-row-sm .dv-col-xxl-6, .dv-row-sm .dv-col-xxl-7, .dv-row-sm .dv-col-xxl-8, .dv-row-sm .dv-col-xxl-9, .dv-row-sm .dv-col-xxl-10, .dv-row-sm .dv-col-xxl-11, .dv-row-sm .dv-colxxl-12, .dv-row-sm .dv-col-xxl,
.dv-row-sm .dv-col-xl-auto {
  padding-left: 7.5px !important;
  padding-right: 7.5px !important;
  margin: 0px !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  body.full-width .container {
    width: 100% !important;
    max-width: 100% !important;
    padding: 0px 5px !important;
  }
}
.page-container {
  background-color: #f8fafb;
}

.page-head {
  top: 0px;
}

.page-head .page-head-content {
  height: auto;
}

.page-container[data-page-route=Workspaces] .layout-side-section, .page-container[data-page-route=Workspaces] .page-title .sidebar-toggle-btn {
  display: none !important;
}
.page-container[data-page-route=Workspaces] .layout-main-section-wrapper {
  width: 100% !important;
}

.centered.splash {
  background: #f8fafb;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transform: inherit;
  -webkit-transform: initial;
  text-align: center;
  vertical-align: middle;
  z-index: 10000000000000000;
}
.centered.splash img {
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -50px 0px 0px -50px;
}

@media (min-width: 992px) {
  [data-page-route=Workspaces] .layout-main {
    height: auto;
  }
}
@media (min-width: 992px) {
  [data-page-route=Workspaces] .layout-main .layout-side-section, [data-page-route=Workspaces] .layout-main .layout-main-section-wrapper {
    height: auto;
    padding-right: 20px;
    padding-left: 20px;
  }
}
.list-sidebar .list-filters input {
  background: #ffffff;
  border: 1px solid #d0d0d0;
}

.awesomplete .input-with-feedback {
  background-color: #ffffff;
  border: 1px solid #bbb;
}

.btn {
  box-shadow: none !important;
  transition: all 0.2s ease-in-out;
  border-radius: 5px;
}

.form-control {
  background: #ffffff;
  border: 1px solid #bbb;
  transition: all 0.2s ease-in-out;
  box-shadow: inset 1px 1px 7px -4px rgba(0, 0, 0, 0.44) !important;
  border-radius: 5px;
}
.form-control:hover {
  border-color: #999999;
}
.form-control:focus {
  background: #ffffff !important;
  border-color: var(--primary) !important;
  box-shadow: inset 1px 1px 7px -4px rgba(0, 0, 0, 0.44), 0 4px 24px 0 rgba(34, 41, 47, 0.1) !important;
}

.btn.btn-default {
  border: 1px solid #d1d3d8 !important;
}
.btn.btn-default:hover {
  background: rgba(235, 239, 243, 0.7) !important;
  border-color: #a2a8b1 !important;
}

.page-form {
  border-bottom: 1px solid #efefef;
}

select.input-xs {
  line-height: 14px;
  padding: 0 12px;
  cursor: pointer;
}

input[type=radio], input[type=checkbox] {
  cursor: pointer;
}

button.btn.btn-default.btn-sm.btn-paging.btn-info {
  background: #14a6ef !important;
  border-color: #14a6ef !important;
  color: #fff !important;
}

.frappe-list {
  padding: 0px !important;
}

.list-row-head {
  background: #f3f3f5;
  border-bottom: 1px solid #e8e8e8;
  cursor: default;
  font-size: 16px;
  font-weight: 600;
}

.report-summary .summary-value {
  height: auto;
  min-height: 30px;
}

.alert.desk-alert .dv-alert-icon {
  margin: 0px;
}
.alert.desk-alert.dv-alert-success, .alert.desk-alert.dv-alert-green {
  border: 1px solid var(--success);
}
.alert.desk-alert.dv-alert-info, .alert.desk-alert.dv-alert-blue {
  border: 1px solid var(--info);
}
.alert.desk-alert.dv-alert-error, .alert.desk-alert.dv-alert-red {
  border: 1px solid var(--danger);
}
.alert.desk-alert.dv-alert-warning, .alert.desk-alert.dv-alert-orange, .alert.desk-alert.dv-alert-yellow {
  border: 1px solid var(--orange);
}

.alert-body {
  text-align: left;
  padding: 0px 15px 10px 15px;
}

.btn svg.icon.icon-sm {
  position: relative;
  top: -1px;
}

.layout-main {
  position: relative;
}
.layout-main .btn-toggle-sidebar {
  background: #fff;
  border: 0;
  box-shadow: -2px 0px 5px -2px rgba(0, 0, 0, 0.1882352941);
  margin: 0px 15px 0px 0px;
  height: 30px;
  width: 20px;
  display: none;
  text-align: center;
  line-height: 29px;
  font-size: 16px;
  padding: 0;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 50;
  color: #666666;
  border-radius: 5px 0px 0px 5px;
  transition: all 0.2s ease-in;
}
.layout-main .btn-toggle-sidebar.sidebar-shown > i {
  display: inline-block;
  transform: rotate(180deg);
}
.layout-main .btn-toggle-sidebar:hover {
  color: #434346;
}

.sidebar-toggle-btn {
  display: none !important;
}

.btn-toggle-main-menu {
  background: #14a6ef;
  border: 0px;
  margin: 0px 15px 0px 0px;
  height: 30px;
  width: 30px;
  min-width: 30px;
  display: inline-block;
  text-align: center;
  line-height: 30px;
  font-size: 16px;
  padding: 0;
  color: #ffffff;
  border-radius: 5px;
  transition: all 0.2s ease-in;
}
.btn-toggle-main-menu:hover {
  background: #108bc9;
}

.page-form .filter-button.btn-primary-light {
  color: #000000;
}

.filter-icon.active use {
  stroke: #000000;
}

.layout-main-section-wrapper {
  margin-bottom: 0;
}

.widget .widget-head .widget-label .widget-title {
  font-weight: 600;
}

.widget.shortcut-widget-box {
  border: 1px solid #ddd;
  box-shadow: 0px 3px 10px -5px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  padding: 10px 12px;
  transition: all 0.2s ease-in-out;
}
.widget.shortcut-widget-box .widget-title {
  transition: all 0.2s ease-in-out;
}
.widget.shortcut-widget-box ~ .divider {
  display: none;
}
.widget.shortcut-widget-box:hover {
  border-color: var(--blue-500);
}
.widget.shortcut-widget-box:hover .widget-title {
  color: var(--blue-500) !important;
}

.widget.dashboard-widget-box, .number-widget-box {
  border: 1px solid #ddd;
  box-shadow: 0px 3px 10px -5px rgba(0, 0, 0, 0.2);
}

.widget.links-widget-box {
  border: 1px solid #ddd;
  box-shadow: 0px 3px 10px -5px rgba(0, 0, 0, 0.2);
}
.widget.links-widget-box .widget-head {
  border-bottom: 1px solid #ddd;
  padding: 8px 15px 10px 15px;
  margin: -13px -13px -8px -13px;
}
.widget.links-widget-box .link-item {
  font-weight: 600;
}

.layout-main-section .frappe-list .result, .layout-main-section .frappe-list .no-result, .layout-main-section .frappe-list .freeze, .layout-main-section .report-wrapper .result, .layout-main-section .report-wrapper .no-result, .layout-main-section .report-wrapper .freeze {
  min-height: calc(100vh - 295px);
}

.theme-setting-colors-select {
  margin: 0px;
}
.theme-setting-colors-select > h4 {
  font-weight: 600;
  margin-bottom: 15px;
}
.theme-setting-colors-select button {
  background: #aaaaaa;
  border: 0px;
  outline: none;
  width: 100%;
  height: 65px;
  text-align: center;
  line-height: 65px;
  color: #ffffff;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
}
.theme-setting-colors-select button.active {
  position: relative;
  box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.45);
}
.theme-setting-colors-select button.active:after {
  content: "\f058";
  font-family: "Font Awesome 5 Pro";
  font-weight: 700;
  font-size: 18px;
  position: absolute;
  line-height: 26px;
  top: 0px;
  right: 5px;
}
.theme-setting-colors-select button[data-color=Blue] {
  background: #14a6ef;
}
.theme-setting-colors-select button[data-color=Green] {
  background: #43a047;
}
.theme-setting-colors-select button[data-color=Red] {
  background: #e53935;
}
.theme-setting-colors-select button[data-color=Orange] {
  background: #fb8c00;
}
.theme-setting-colors-select button[data-color=Yellow] {
  background: #ffca28;
}
.theme-setting-colors-select button[data-color=Pink] {
  background: #ec407a;
}
.theme-setting-colors-select button[data-color=Violet] {
  background: #ab47bc;
}

.form-tabs-sticky-down {
  top: calc(var(--navbar-height) + var(--page-head-height) - 25px) !important;
}

.frappe-control[data-fieldtype=Icon] .selected-icon {
  top: calc(50% - 1px);
  font-size: 18px;
}

.icon-picker .icons .icon-wrapper {
  font-size: 20px;
  opacity: 0.8;
  color: #444;
  border: 1px solid #aaa;
  border-radius: 4px;
  margin: 2.4px 2px;
  transition: all 0.2s ease-in-out;
}
.icon-picker .icons .icon-wrapper:hover {
  border-color: #444444;
  color: #000000;
  opacity: 1;
}
.icon-picker .icons .icon-wrapper > i {
  display: block;
  margin: 0px auto;
}

/* START TOOLTIP STYLES */
[tooltip] {
  position: relative; /* opinion 1 */
}

/* Applies to all tooltips */
[tooltip]::before,
[tooltip]::after {
  text-transform: none; /* opinion 2 */
  font-family: inherit !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  pointer-events: none;
  position: absolute;
  display: none;
  opacity: 0;
}

[tooltip]::before {
  content: "";
  border: 5px solid transparent; /* opinion 4 */
  z-index: 1001; /* absurdity 1 */
}

[tooltip]::after {
  content: attr(tooltip); /* magic! */
  /* most of the rest of this is opinion */
  font-family: Helvetica, sans-serif;
  text-align: center;
  /*
    Let the content set the size of the tooltips
    but this will also keep them from being obnoxious
  */
  min-width: 3em;
  max-width: 21em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 1ch 1.5ch;
  border-radius: 0.3ch;
  box-shadow: 0 1em 2em -0.5em rgba(0, 0, 0, 0.35);
  background: #333;
  color: #fff;
  z-index: 1000; /* absurdity 2 */
}

/* Make the tooltips respond to hover */
[tooltip]:hover::before,
[tooltip]:hover::after {
  display: block;
}

/* don't show empty tooltips */
[tooltip=""]::before,
[tooltip=""]::after {
  display: none !important;
}

/* FLOW: UP */
[tooltip]:not([flow])::before,
[tooltip][flow^=up]::before {
  bottom: 100%;
  border-bottom-width: 0;
  border-top-color: #333;
}

[tooltip]:not([flow])::after,
[tooltip][flow^=up]::after {
  bottom: calc(100% + 5px);
}

[tooltip]:not([flow])::before,
[tooltip]:not([flow])::after,
[tooltip][flow^=up]::before,
[tooltip][flow^=up]::after {
  left: 50%;
  transform: translate(-50%, -0.5em);
}

/* FLOW: DOWN */
[tooltip][flow^=down]::before {
  top: 100%;
  border-top-width: 0;
  border-bottom-color: #333;
}

[tooltip][flow^=down]::after {
  top: calc(100% + 5px);
}

[tooltip][flow^=down]::before,
[tooltip][flow^=down]::after {
  left: 50%;
  transform: translate(-50%, 0.5em);
}

/* FLOW: LEFT */
[tooltip][flow^=left]::before {
  top: 50%;
  border-right-width: 0;
  border-left-color: #333;
  left: calc(0em - 5px);
  transform: translate(-0.5em, -50%);
}

[tooltip][flow^=left]::after {
  top: 50%;
  right: calc(100% + 5px);
  transform: translate(-0.5em, -50%);
}

/* FLOW: RIGHT */
[tooltip][flow^=right]::before {
  top: 50%;
  border-left-width: 0;
  border-right-color: #333;
  right: calc(0em - 5px);
  transform: translate(0.5em, -50%);
}

[tooltip][flow^=right]::after {
  top: 50%;
  left: calc(100% + 5px);
  transform: translate(0.5em, -50%);
}

/* KEYFRAMES */
@keyframes tooltips-vert {
  to {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}
@keyframes tooltips-horz {
  to {
    opacity: 1;
    transform: translate(0, -50%);
  }
}
/* FX All The Things */
[tooltip]:not([flow]):hover::before,
[tooltip]:not([flow]):hover::after,
[tooltip][flow^=up]:hover::before,
[tooltip][flow^=up]:hover::after,
[tooltip][flow^=down]:hover::before,
[tooltip][flow^=down]:hover::after {
  animation: tooltips-vert 300ms ease-out forwards;
}

[tooltip][flow^=left]:hover::before,
[tooltip][flow^=left]:hover::after,
[tooltip][flow^=right]:hover::before,
[tooltip][flow^=right]:hover::after {
  animation: tooltips-horz 300ms ease-out forwards;
}

.chosen-select {
  width: 100%;
}

.chosen-select-deselect {
  width: 100%;
}

.chosen-container {
  display: inline-block;
  position: relative;
  width: 100% !important;
  font-size: 1rem;
  text-align: left;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.chosen-container .chosen-drop {
  background: #fff;
  border: 1px solid var(--primary);
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  margin-top: -1px;
  position: absolute;
  top: 100%;
  left: -9000px;
  z-index: 1060;
}
.chosen-container .chosen-results {
  margin: 0;
  position: relative;
  max-height: 15rem;
  padding: 0.5rem 0 0 0;
  color: #6c757d;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.chosen-container .chosen-results li {
  display: none;
  line-height: 1.5;
  list-style: none;
  margin: 0;
  padding: 0.25rem 0.25rem 0.25rem 1.5rem;
}
.chosen-container .chosen-results li em {
  font-style: normal;
  color: #6c757d;
}
.chosen-container .chosen-results li.group-result {
  display: list-item;
  cursor: default;
  padding-left: 0.75rem;
  color: #adb5bd;
  font-weight: 400;
  text-transform: uppercase;
}
.chosen-container .chosen-results li.group-option {
  padding-left: 1.5rem;
}
.chosen-container .chosen-results li.active-result {
  cursor: pointer;
  display: list-item;
}
.chosen-container .chosen-results li.result-selected {
  color: #007bff;
  font-weight: 600;
}
.chosen-container .chosen-results li.result-selected:before {
  display: inline-block;
  position: relative;
  top: 0.3rem;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: -1.25rem;
  content: "";
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23495057' d='M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z'/%3E%3C/svg%3E");
  background-size: 1.25rem 1.25rem;
  background-position: center center;
  background-repeat: no-repeat;
}
.chosen-container .chosen-results li.highlighted {
  background-color: #007bff;
  background-image: none;
  color: #fff;
}
.chosen-container .chosen-results li.highlighted:before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='white' d='M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z'/%3E%3C/svg%3E");
}
.chosen-container .chosen-results li.highlighted em {
  background: 0 0;
  color: rgba(255, 255, 255, 0.8);
}
.chosen-container .chosen-results li.disabled-result {
  display: list-item;
  color: #ced4da;
}
.chosen-container .chosen-results .no-results {
  display: list-item;
  padding: 0.25rem 0 1rem 1.065rem;
  color: #dc3545;
}
.chosen-container .chosen-results-scroll {
  background: #fff;
  margin: 0 0.25rem;
  position: absolute;
  text-align: center;
  width: 20rem;
  z-index: 1;
}
.chosen-container .chosen-results-scroll span {
  display: inline-block;
  height: 1.5;
  text-indent: -5000px;
  width: 0.5rem;
}
.chosen-container:valid .chosen-choices {
  border-color: #28a745;
}
.chosen-container:valid .chosen-drop {
  border-color: #28a745;
}
.chosen-container:valid .chosen-single {
  border-color: #28a745;
}
.chosen-container:invalid .chosen-choices {
  border-color: #dc3545;
}
.chosen-container:invalid .chosen-drop {
  border-color: #dc3545;
}
.chosen-container:invalid .chosen-single {
  border-color: #dc3545;
}

.chosen-container.chosen-with-drop .chosen-drop {
  left: 0;
  right: 0;
}

.chosen-container-single .chosen-single {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #bbb;
  color: #6c757d;
  display: block;
  height: 33.5px;
  overflow: hidden;
  line-height: 32px;
  padding: 0 0 0 0.5rem;
  position: relative;
  text-decoration: none;
  white-space: nowrap;
  border-radius: 5px;
}
.chosen-container-single .chosen-single abbr {
  display: inline-block;
  position: absolute;
  top: 0.325rem;
  right: 2rem;
  width: 2rem;
  height: 1.5rem;
  cursor: pointer;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23dc3545' d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/%3E%3C/svg%3E");
  background-size: 1.5rem 1.5rem;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #fff;
  box-shadow: 4px 0 16px 16px #fff;
}
.chosen-container-single .chosen-single abbr:hover {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23921925' d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/%3E%3C/svg%3E");
}
.chosen-container-single .chosen-single span {
  display: block;
  margin-right: 1.5rem;
  text-overflow: ellipsis;
}
.chosen-container-single .chosen-single div {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 2rem;
  height: 100%;
  padding-left: 0.5rem;
  background-color: #fff;
}
.chosen-container-single .chosen-single div:after {
  display: inline-block;
  position: relative;
  top: 0.125rem;
  left: -1rem;
  width: 2rem;
  height: 2rem;
  content: "";
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23adb5bd' d='M7.41 7.84L12 12.42l4.59-4.58L18 9.25l-6 6-6-6z'/%3E%3C/svg%3E");
  background-size: 2rem 2rem;
  background-position: center center;
  background-repeat: no-repeat;
}
.chosen-container-single .chosen-single:not(.chosen-single-with-deselect) div:after {
  background-color: #fff;
  box-shadow: 4px 0 16px 16px #fff;
}
.chosen-container-single .chosen-single.chosen-disabled .chosen-single abbr:hover {
  background-position: right 2px;
}
.chosen-container-single .chosen-default {
  color: #adb5bd;
}
.chosen-container-single a:not([href]):not([tabindex]) {
  color: #6c757d !important;
}
.chosen-container-single a:not([href]):not([tabindex]).chosen-single:not(.chosen-default) {
  color: #6c757d !important;
}
.chosen-container-single .chosen-search-input {
  border: none;
}
.chosen-container-single .chosen-search {
  margin: 0;
  padding: 0.5rem 0.5rem 0 0.5rem;
  position: relative;
  white-space: nowrap;
  z-index: 1000;
}
.chosen-container-single .chosen-search:after {
  display: inline-block;
  position: relative;
  top: 0.365rem;
  left: -1.75rem;
  width: 1.25rem;
  height: 1.25rem;
  content: "";
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23adb5bd' d='M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z'/%3E%3C/svg%3E");
  background-size: 1.25rem 1.25rem;
  background-position: center center;
  background-repeat: no-repeat;
}
.chosen-container-single .chosen-search input[type=text] {
  background-color: #f8f9fa;
  border: 1px solid #ced4da;
  outline: 0;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  padding: 0.25rem 1rem 0.25rem 0.5rem;
  width: 100%;
}
.chosen-container-single .chosen-drop {
  margin-top: -1px;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  background-clip: padding-box;
}

.chosen-container-single-nosearch .chosen-search {
  display: none;
}

.chosen-container-multi .chosen-choices {
  background-color: #fff;
  border: 1px solid #ced4da;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  cursor: text;
  height: auto !important;
  margin: 0;
  padding: 0.175rem;
  overflow: hidden;
  position: relative;
}
.chosen-container-multi .chosen-choices li {
  float: left;
  list-style: none;
}
.chosen-container-multi .chosen-choices .search-field {
  margin: 0;
  padding: 0;
  white-space: nowrap;
}
.chosen-container-multi .chosen-choices .search-field input[type=text] {
  width: 100% !important;
  margin: 0;
  padding: 0.175rem 0.175rem 0.175rem 0.5rem;
  border: 0 !important;
  background: 0 0 !important;
  color: #6c757d;
  outline: 0;
}
.chosen-container-multi .chosen-choices .search-field .default {
  color: #ced4da;
}
.chosen-container-multi .chosen-choices .search-choice {
  background-clip: padding-box;
  position: relative;
  max-width: 320px;
  margin: 0.175rem 0.25rem;
  padding: 0.25rem 1.5rem 0.25rem 0.25rem;
  border: 1px solid #ced4da;
  background-color: #f8f9fa;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  cursor: default;
  font-size: 0.875rem;
  line-height: 1;
  color: #6c757d;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.chosen-container-multi .chosen-choices .search-choice .search-choice-close {
  display: inline-block;
  position: absolute;
  top: 0.2rem;
  right: 0.125rem;
  width: 1rem;
  height: 1rem;
  cursor: pointer;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23dc3545' d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/%3E%3C/svg%3E");
  background-size: 1rem 1rem;
  background-position: center center;
  background-repeat: no-repeat;
}
.chosen-container-multi .chosen-choices .search-choice .search-choice-close:hover {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23921925' d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/%3E%3C/svg%3E");
}
.chosen-container-multi .chosen-choices .search-choice-focus {
  background: #dee2e6;
}
.chosen-container-multi .chosen-choices .search-choice-focus .search-choice-close {
  background-position: right -11px;
}
.chosen-container-multi .chosen-drop .result-selected {
  display: none;
}

.chosen-container-active .chosen-single {
  border: 1px solid var(--primary);
  box-shadow: 0 0 0 0.2rem rgba(128, 189, 255, 0.5);
  transition: border linear 0s, box-shadow linear 0s;
}
.chosen-container-active .chosen-choices {
  border: 1px solid var(--primary);
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  transition: border linear 0s, box-shadow linear 0s;
}
.chosen-container-active .chosen-choices .search-field input[type=text] {
  color: #6c757d !important;
}

.chosen-container-active.is-valid .chosen-single {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.4);
}

.chosen-container-active.is-invalid .chosen-single {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.4);
}

.chosen-container-active.chosen-with-drop .chosen-single {
  background-color: #fff;
  border: 1px solid var(--primary);
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  box-shadow: none;
  transition: border linear 0s, box-shadow linear 0s;
}
.chosen-container-active.chosen-with-drop .chosen-single div:after {
  display: inline-block;
  position: relative;
  top: 0.125rem;
  left: -1rem;
  width: 2rem;
  height: 2rem;
  content: "";
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23adb5bd' d='M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z'/%3E%3C/svg%3E");
  background-size: 2rem 2rem;
  background-position: center center;
  background-repeat: no-repeat;
}
.chosen-container-active.chosen-with-drop .chosen-choices {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.chosen-container-active.chosen-with-drop .chosen-search-input {
  display: inline-block;
}

.chosen-disabled {
  cursor: default;
  opacity: 0.5 !important;
}
.chosen-disabled .chosen-single {
  cursor: default;
}
.chosen-disabled .chosen-choices .search-choice .search-choice-close {
  cursor: default;
}

.chosen-container-optgroup-clickable li.group-result {
  text-transform: none !important;
}
.chosen-container-optgroup-clickable li.group-result:hover {
  background-color: var(--primary);
  color: #fff;
  cursor: pointer;
}
.chosen-container-optgroup-clickable li.group-result:hover em {
  color: #fff;
}

.chosen-container.is-valid .chosen-choices {
  border-color: #28a745;
}
.chosen-container.is-valid .chosen-drop {
  border-color: #28a745;
}
.chosen-container.is-valid .chosen-single {
  border-color: #28a745;
}

.chosen-container.is-invalid .chosen-choices {
  border-color: #dc3545;
}
.chosen-container.is-invalid .chosen-drop {
  border-color: #dc3545;
}
.chosen-container.is-invalid .chosen-single {
  border-color: #dc3545;
}

input[type=text].chosen-focus-input {
  position: absolute;
  top: -9000px;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  background: 0 0 !important;
  border: 0 !important;
  outline: 0;
}

.input-group:nth-of-type(1) .chosen-container .chosen-choices {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group:not(:nth-of-type(1)) .chosen-container .chosen-choices {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group:not(:nth-of-type(1)) .input-group-prepend .input-group-text {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.input-group .chosen-container {
  position: relative;
  flex: 1 1 auto;
  width: 1% !important;
}
.input-group .chosen-choices .search-field {
  min-height: calc(1.5em + 0.75rem);
}

@media (prefers-reduced-motion: reduce) {
  .chosen-container-active .chosen-single {
    transition: none;
  }
  .chosen-container-active .chosen-choices {
    transition: none;
  }
  .chosen-container-active.chosen-with-drop .chosen-single {
    transition: none;
  }
}
.chosen-container-single .chosen-single div:after {
  background: none !important;
  display: none !important;
}

html[data-theme-mode=light] .navbar {
  background-color: #b9d4e6;
}

@media (min-width: 768px) {
  .navbar {
    padding: 0 80px 0 120px;
  }
}
.btn-open-mobile-menu {
  background: transparent;
  border: 0px;
  float: left;
  margin: 0px -10px 0px -15px;
  height: 46px;
  width: 40px;
  text-align: center;
  line-height: 49px;
  font-size: 25px;
  padding: 0;
  color: #4b84a0;
  transition: all 0.2s ease-in;
  display: none;
}
.btn-open-mobile-menu:hover {
  color: #14a6ef;
}

.dv-navbar-overlay {
  background: #f9fafa;
  width: 100%;
  height: 72px;
  position: fixed;
  top: 0;
  margin-top: 0;
  z-index: 12;
  left: 0;
  right: 0;
  display: none !important;
}

.f {
  background: #fff;
  border-bottom: 1px solid #e6e6e6;
  width: inherit;
  height: 60px;
  position: fixed;
  top: 0px;
  margin-top: 0;
  z-index: 280;
  left: 0px;
  right: 0px;
  border-radius: 0px;
}
.f .dv-nav-right {
  float: right;
  margin: 0px 0px 0px 0px;
}
.f .dv-nav-right > ul > li {
  display: block;
  float: right;
  margin: 3px 0px 0px 10px;
}
.f .dv-nav-right > ul > li.dropdown-user > a {
  margin: 0px;
  overflow: hidden;
  padding-left: 5px;
  position: relative;
}
.f .dv-nav-right > ul > li.dropdown-user > a .dv-nav-avatar {
  float: right;
  display: block;
  margin-left: 10px;
}
.f .dv-nav-right > ul > li.dropdown-user > a .avatar-status-online {
  display: block;
  background: #3cd090;
  border: 2px solid #ffffff;
  width: 12px;
  height: 12px;
  position: absolute;
  right: 15px;
  bottom: 5px;
  border-radius: 100%;
  z-index: 15;
}
.f .dv-nav-right > ul > li.dropdown-user > a .dv-user-info {
  margin: 0px;
  text-align: right;
  overflow: hidden;
}
.f .dv-nav-right > ul > li.dropdown-user > a .dv-user-info span {
  display: block;
}
.f .dv-nav-right > ul > li.dropdown-user > a .dv-user-info span.user-name {
  font-weight: 600;
  font-size: 16px;
  color: #2490ef;
  line-height: 16px;
  margin-top: 2px;
}
.f .dv-nav-right > ul > li.dropdown-user > a .dv-user-info span.user-status {
  font-size: 12px;
  color: #666666;
}
.f .dv-nav-right > ul > li.dropdown-user > a:after {
  display: none !important;
}
.f .dv-nav-right > ul > li.dropdown-user .dropdown-menu {
  box-shadow: 0px 1px 4px rgba(17, 43, 66, 0.1), 0px 2px 6px rgba(17, 43, 66, 0.08) !important;
  border: none;
  min-width: 150px;
  margin-top: -3px;
}
.f .dv-nav-right > ul > li.dropdown-user .dropdown-menu .dropdown-item {
  font-size: 14px;
  color: #666666 !important;
  font-weight: 400;
  padding: 5px 10px;
  transition: all 0.2s ease-in-out;
}
.f .dv-nav-right > ul > li.dropdown-user .dropdown-menu .dropdown-item:hover, .f .dv-nav-right > ul > li.dropdown-user .dropdown-menu .dropdown-item:focus {
  color: #14a6ef !important;
  text-decoration: none;
  background-color: #dfeef5;
}
.f .dv-nav-right > ul > li.dropdown-user .dropdown-menu .dropdown-item.active, .f .dv-nav-right > ul > li.dropdown-user .dropdown-menu .dropdown-item:active {
  color: #ffffff !important;
  background-color: #14a6ef;
}
.f .dv-nav-right > ul > li.dropdown-user .dropdown-menu .dropdown-item.dropdown-item-username {
  font-size: 14px;
  font-weight: 600;
  width: 142px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #2490ef !important;
}
.f .dv-nav-right > ul > li.dv-dropdown-notifications > a {
  display: block;
  padding: 0;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  margin-top: 10px;
  font-size: 21px;
  font-weight: 400;
  color: #555 !important;
  transition: all 0.1s ease-in-out;
}
.f .dv-nav-right > ul > li.dv-dropdown-notifications > a:after {
  display: none !important;
}
.f .dv-nav-right > ul > li.dv-dropdown-notifications > a:hover {
  color: #14a6ef !important;
}
.f .dv-nav-right > ul > li.dv-dropdown-notifications .notifications-list {
  margin-top: 10px;
}
.f .dv-nav-right > ul > li.dropdown-full-screen > button, .f .dv-nav-right > ul > li.dropdown-files > button, .f .dv-nav-right > ul > li.dropdown-search > button {
  background: transparent;
  border: 0px !important;
  box-shadow: none !important;
  display: block;
  padding: 0;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  margin-top: 10px;
  font-size: 19px;
  font-weight: 400;
  color: #555 !important;
  transition: all 0.1s ease-in-out;
}
.f .dv-nav-right > ul > li.dropdown-full-screen > button:hover, .f .dv-nav-right > ul > li.dropdown-files > button:hover, .f .dv-nav-right > ul > li.dropdown-search > button:hover {
  color: #14a6ef !important;
}
.f .dv-nav-right > ul > li.dropdown-search {
  display: none;
}
.f .dv-nav-right > ul > li.dropdown-language > a.dropdown-lang-link {
  background: transparent;
  border: 0px !important;
  box-shadow: none !important;
  display: block;
  padding: 0;
  height: 30px;
  line-height: 30px;
  text-align: center;
  margin-top: 10px;
  font-size: 16px;
  font-weight: 400;
  color: #555 !important;
  transition: all 0.1s ease-in-out;
}
.f .dv-nav-right > ul > li.dropdown-language > a.dropdown-lang-link .dv-lang-flag {
  background: #eee;
  background-size: 100% 100% !important;
  display: block;
  float: left;
  width: 22px;
  height: 22px;
  margin-right: 5px;
  margin-top: 5px;
  border-radius: 100%;
}
.f .dv-nav-right > ul > li.dropdown-language > a.dropdown-lang-link .dv-lang-flag.lang-en {
  background: url("/assets/datavalue_theme_14/images/us.svg") no-repeat center center;
}
.f .dv-nav-right > ul > li.dropdown-language > a.dropdown-lang-link .dv-lang-flag.lang-ar {
  background: url("/assets/datavalue_theme_14/images/sa.svg") no-repeat center center;
}
.f .dv-nav-right > ul > li.dropdown-language .dropdown-menu {
  box-shadow: 0px 1px 4px rgba(17, 43, 66, 0.1), 0px 2px 6px rgba(17, 43, 66, 0.08) !important;
  border: none;
  margin-top: 10px;
  width: 100px;
  min-width: 100px;
}
.f .dv-nav-right > ul > li.dropdown-language .dropdown-menu .dropdown-item {
  font-size: 14px;
  color: #666666 !important;
  font-weight: 400;
  padding: 5px 10px;
  transition: all 0.2s ease-in-out;
}
.f .dv-nav-right > ul > li.dropdown-language .dropdown-menu .dropdown-item .dv-lang-flag {
  background: #eee;
  background-size: 100% 100% !important;
  display: block;
  float: left;
  width: 20px;
  height: 20px;
  margin-right: 5px;
  margin-top: 0px;
  border-radius: 100%;
}
.f .dv-nav-right > ul > li.dropdown-language .dropdown-menu .dropdown-item .dv-lang-flag.lang-en {
  background: url("/assets/datavalue_theme_14/images/us.svg") no-repeat center center;
}
.f .dv-nav-right > ul > li.dropdown-language .dropdown-menu .dropdown-item .dv-lang-flag.lang-ar {
  background: url("/assets/datavalue_theme_14/images/sa.svg") no-repeat center center;
}
.f .dv-nav-right > ul > li.dropdown-language .dropdown-menu .dropdown-item:hover, .f .dv-nav-right > ul > li.dropdown-language .dropdown-menu .dropdown-item:focus {
  color: #14a6ef !important;
  text-decoration: none;
  background-color: #dfeef5;
}
.f .dv-nav-right > ul > li.dropdown-language .dropdown-menu .dropdown-item.active, .f .dv-nav-right > ul > li.dropdown-language .dropdown-menu .dropdown-item:active {
  color: #ffffff !important;
  background-color: #14a6ef;
}
.f .dv-nav-left {
  float: left;
  margin: 6px;
}
.f .dv-nav-left .btn-open-modules {
  background: transparent;
  border: 0px;
  float: left;
  margin: 0px 0px 0px -3px;
  height: 46px;
  width: 46px;
  text-align: center;
  line-height: 49px;
  font-size: 21px;
  padding: 0;
  color: #4b84a0;
  transition: all 0.2s ease-in;
}
.f .dv-nav-left .btn-open-modules:hover {
  color: #14a6ef;
}
.f .dv-nav-left .btn-open-mobile-menu {
  background: transparent;
  border: 0px;
  float: left;
  margin: 0px -10px 0px -15px;
  height: 46px;
  width: 40px;
  text-align: center;
  line-height: 49px;
  font-size: 25px;
  padding: 0;
  color: #4b84a0;
  transition: all 0.2s ease-in;
  display: none;
}
.f .dv-nav-left .btn-open-mobile-menu:hover {
  color: #14a6ef;
}
.f .dv-nav-left .logo {
  background: transparent;
  background-size: contain;
  width: 142px;
  height: 27px;
  display: block;
  float: left;
  position: relative;
  margin: 7px 0px 0px 18px;
}
.f .dv-nav-left .logo a {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 10;
}
.f .dv-nav-left .logo.has-company-logo {
  height: 40px;
  max-width: 142px;
  margin-top: 3px;
}
.f .dv-nav-left .logo.has-company-logo > a > img {
  height: 40px;
}
.f .dv-nav-left .navbar-breadcrumbs {
  float: left;
  margin-left: 0;
  position: relative;
  left: 140px;
  transition: all 0.2s ease-in-out;
}
.f .dv-nav-left .navbar-breadcrumbs .navbar-nav {
  flex-direction: row;
  margin-top: 11px;
  float: left;
  margin-left: 0px !important;
}
.f .dv-nav-left .navbar-breadcrumbs .navbar-nav > li > a {
  font-size: 16px !important;
  font-weight: 600;
  text-decoration: none !important;
}
.f .dv-nav-left .navbar-breadcrumbs .navbar-nav > li > a:before {
  position: relative;
  top: -2px;
}
.f .dv-nav-left .navbar-breadcrumbs .navbar-nav > li:first-child > a:before {
  display: none !important;
}
.f .dv-nav-search {
  float: right;
  width: 310px;
  height: 40px;
  margin: 12px 10px;
  position: relative;
}
.f .dv-nav-search .form-control {
  background: #f9fafa;
  border: 1px solid #ddd;
  box-shadow: none !important;
  height: 35px;
  padding-left: 30px;
  font-size: 14px;
}
.f .dv-nav-search .search-icon {
  position: absolute;
  top: 8px;
  left: 13px;
  font-size: 14px;
  color: #666;
}
.f .dv-nav-search .dv-nav-search-close {
  background: transparent;
  border: 0px;
  font-size: 19px;
  color: #666;
  position: absolute;
  top: 15px;
  right: 17px;
  cursor: pointer;
  z-index: 15;
  display: none;
}
.f .dv-nav-search .awesomplete > ul {
  margin-top: 6px;
}
.f .dropdown-menu {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-name: slideIn;
}

.side-menu {
  background: #ffffff;
  /* border-right: 1px solid #ddd; */
  border-right: 1px solid #e6e6e6;
  width: 340px;
  position: fixed;
  left: 0px;
  top: 0px;
  bottom: 0px;
  overflow: hidden;
  z-index: 250;
  transition: all 0.3s ease-in-out;
}
.side-menu .modules-menu {
  background: #ffffff;
  box-shadow: var(--card-shadow);
  position: absolute;
  width: 340px;
  top: 48px;
  bottom: 0px;
  left: 0px;
  z-index: 1000;
  display: none;
}
.side-menu .modules-menu h4 {
  margin: 0px;
  font-size: 18px;
}
.side-menu .modules-menu .modules-menu-list {
  margin: 15px 20px;
}
.side-menu .modules-menu .modules-menu-list a {
  border: 1px solid transparent;
  display: block;
  margin: 0px;
  padding: 0px 5px 0px 10px;
  font-size: 14px;
  height: 40px;
  line-height: 40px;
  font-weight: 600;
  color: #4b84a0;
  overflow: hidden;
  border-radius: 4px;
  word-break: break-all;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: all 0.2s ease-in;
}
.side-menu .modules-menu .modules-menu-list a i {
  font-size: 17px;
  font-weight: 300;
  margin-right: 2px;
}
.side-menu .modules-menu .modules-menu-list a i.fal {
  position: relative;
}
.side-menu .modules-menu .modules-menu-list a i[class^=flaticon-] {
  position: relative;
  top: 3px;
  display: inline-block;
}
.side-menu .modules-menu .modules-menu-list a:hover {
  box-shadow: 0px 0px 8px -3px rgba(0, 0, 0, 0.3490196078);
  border-color: #e6e6e6;
  color: #14a6ef;
}
.side-menu .modules-menu .modules-menu-list a.active {
  color: #14a6ef;
  font-weight: 700;
}
.side-menu .side-menu-icons {
  border-right: 1px solid #e6e6e6;
  width: 80px;
  position: absolute;
  top: 48px;
  bottom: 0px;
  left: 0px;
}
.side-menu .side-menu-icons > ul {
  position: absolute;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  margin: 5px 0px 15px 0px;
}
.side-menu .side-menu-icons > ul > li {
  margin: 10px 15px;
  display: block;
}
.side-menu .side-menu-icons > ul > li > a {
  border: 1px solid transparent;
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 24px;
  color: #4b84a0;
  display: block;
  text-align: center;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
}
.side-menu .side-menu-icons > ul > li > a i.fal {
  position: relative;
}
.side-menu .side-menu-icons > ul > li > a i[class^=flaticon-] {
  position: relative;
  top: 3px;
  display: inline-block;
}
.side-menu .side-menu-icons > ul > li > a:hover {
  background: #f7f9fe;
  border-color: #14a6ef;
  color: #14a6ef;
}
.side-menu .side-menu-icons > ul > li.active > a {
  background: #14a6ef;
  box-shadow: 0px 1px 8px -2px rgba(0, 0, 0, 0.25);
  color: #ffffff;
}
.side-menu .side-menu-icons.menu-icons-with-label > ul > li > a {
  width: auto;
  height: auto;
  line-height: inherit;
  font-size: 14px;
  transition: all 0.2s ease-in-out;
}
.side-menu .side-menu-icons.menu-icons-with-label > ul > li > a > div {
  border: 1px solid transparent;
  width: 50px;
  height: 50px;
  display: block;
  line-height: 50px;
  font-size: 24px;
  margin: 0px;
  color: #4b84a0;
  border-radius: 5px;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
}
.side-menu .side-menu-icons.menu-icons-with-label > ul > li > a > span {
  display: block;
  text-align: center;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 5px -6px 0px -5px;
}
.side-menu .side-menu-icons.menu-icons-with-label > ul > li > a:hover {
  background: transparent !important;
  border-color: transparent !important;
  color: #14a6ef;
}
.side-menu .side-menu-icons.menu-icons-with-label > ul > li > a:hover > div {
  background: #f7f9fe;
  border-color: #14a6ef;
  color: #14a6ef;
}
.side-menu .side-menu-icons.menu-icons-with-label > ul > li.active > a {
  background: transparent !important;
  box-shadow: none;
  color: #14a6ef;
}
.side-menu .side-menu-icons.menu-icons-with-label > ul > li.active > a > div {
  background: #14a6ef;
  box-shadow: 0px 1px 8px -2px rgba(0, 0, 0, 0.25);
  color: #ffffff;
}
.side-menu .side-menu-items {
  width: 260px;
  position: absolute;
  top: 48px;
  bottom: 0px;
  left: 80px;
}
.side-menu .side-menu-items > ul {
  margin: 0px;
  padding: 0px;
}
.side-menu .side-menu-items > ul > li {
  margin: 0px;
}
.side-menu .side-menu-items > ul > li.navigation-divider {
  padding: 10px 20px;
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 0.5px;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #666666;
}
.side-menu .side-menu-items > ul > li.navigation-divider .sub-menu-icon {
  margin-right: 2px;
  font-size: 18px;
  position: relative;
  top: 0px;
  opacity: 0.8;
}
.side-menu .side-menu-items > ul.dropdown-list {
  position: absolute;
  top: 65px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  margin-bottom: 15px;
}
.side-menu .side-menu-items > ul.dropdown-list > li > a {
  background: rgba(243, 246, 253, 0.7019607843);
  border: 1px solid #d1d3d8;
  display: block;
  margin: 0px 15px 10px 15px;
  padding: 8px 15px;
  color: #666;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
}
.side-menu .side-menu-items > ul.dropdown-list > li > a .sub-menu-arrow {
  float: right;
  margin: 3px 0px;
  font-size: 16px;
  color: #aaa;
  transition: all 0.2s ease-in-out;
}
.side-menu .side-menu-items > ul.dropdown-list > li > a:hover {
  border-color: #14a6ef;
  color: #14a6ef;
}
.side-menu .side-menu-items > ul.dropdown-list > li > a:hover .sub-menu-arrow {
  color: #14a6ef;
}
.side-menu .side-menu-items > ul.dropdown-list > li > ul {
  margin: 0px 0px 15px 0px;
  padding: 0px;
  display: none;
}
.side-menu .side-menu-items > ul.dropdown-list > li > ul > li {
  margin: 0px;
}
.side-menu .side-menu-items > ul.dropdown-list > li > ul > li > a {
  padding: 0px;
  margin: 0px 15px 5px 30px;
  transition: all 0.2s ease-in-out;
  height: auto;
  line-height: initial;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  display: block;
  text-decoration: none;
}
.side-menu .side-menu-items > ul.dropdown-list > li > ul > li > a:before {
  display: inline-block;
  content: "-";
  font-weight: 400;
  margin: 0px 6px 0px 0px;
  transition: all 0.2s ease-in-out;
}
.side-menu .side-menu-items > ul.dropdown-list > li > ul > li > a:hover {
  color: #14a6ef;
}
.side-menu .side-menu-items > ul.dropdown-list > li > ul > li > a:hover:before {
  margin-right: 12px;
}
.side-menu .side-menu-items > ul.dropdown-list > li > ul > li.active > a {
  color: #14a6ef;
  font-weight: 600;
}
.side-menu .side-menu-items > ul.dropdown-list > li.active > a {
  background: #14a6ef;
  border-color: #14a6ef;
  color: #ffffff;
}
.side-menu .side-menu-items > ul.dropdown-list > li.active > a .sub-menu-arrow {
  color: #ffffff;
  transform: rotate(180deg);
}
.side-menu .side-menu-items > ul.dropdown-list > li.active.hide-sub-menu > a .sub-menu-arrow {
  transform: rotate(0deg);
}

.side-mobile-menu {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  width: 250px;
  position: fixed;
  left: 0px;
  top: 60px;
  bottom: 0px;
  overflow: auto;
  display: none;
}
.side-mobile-menu > ul.mobile-modules-list {
  margin: 10px 0px;
}
.side-mobile-menu > ul.mobile-modules-list > li {
  margin: 0px 10px;
}
.side-mobile-menu > ul.mobile-modules-list > li > a {
  background: transparent;
  border: 1px solid transparent;
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  color: #30434f;
  display: block;
  border-radius: 5px;
  padding: 0px 10px;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
}
.side-mobile-menu > ul.mobile-modules-list > li > a:active, .side-mobile-menu > ul.mobile-modules-list > li > a:focus {
  background: #14a6ef;
  border-color: #14a6ef;
  box-shadow: 0px 1px 4px -1px rgba(0, 0, 0, 0.25);
  color: #ffffff !important;
}
.side-mobile-menu > ul.mobile-modules-list > li.active > a {
  background: #14a6ef;
  border-color: #14a6ef;
  box-shadow: 0px 1px 4px -1px rgba(0, 0, 0, 0.25);
  color: #ffffff !important;
}
.side-mobile-menu > ul.mobile-modules-menu-list {
  margin: 10px 0px;
}
.side-mobile-menu > ul.mobile-modules-menu-list > li > a {
  background: rgba(243, 246, 253, 0.7019607843);
  border: 1px solid #d1d3d8;
  display: block;
  margin: 0px 15px 10px 15px;
  padding: 8px 15px;
  color: #666;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
}
.side-mobile-menu > ul.mobile-modules-menu-list > li > a .sub-menu-arrow {
  float: right;
  margin: 3px 0px;
  font-size: 16px;
  color: #aaa;
  transition: all 0.2s ease-in-out;
}
.side-mobile-menu > ul.mobile-modules-menu-list > li > a:hover {
  border-color: #14a6ef;
  color: #14a6ef;
}
.side-mobile-menu > ul.mobile-modules-menu-list > li > a:hover .sub-menu-arrow {
  color: #14a6ef;
}
.side-mobile-menu > ul.mobile-modules-menu-list > li > ul {
  margin: 0px 0px 15px 0px;
  padding: 0px;
  display: none;
}
.side-mobile-menu > ul.mobile-modules-menu-list > li > ul > li {
  margin: 0px;
}
.side-mobile-menu > ul.mobile-modules-menu-list > li > ul > li > a {
  padding: 0px;
  margin: 0px 15px 5px 30px;
  transition: all 0.2s ease-in-out;
  height: auto;
  line-height: initial;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  display: block;
  text-decoration: none;
}
.side-mobile-menu > ul.mobile-modules-menu-list > li > ul > li > a:before {
  display: inline-block;
  content: "-";
  font-weight: 400;
  margin: 0px 6px 0px 0px;
  transition: all 0.2s ease-in-out;
}
.side-mobile-menu > ul.mobile-modules-menu-list > li > ul > li > a:hover {
  color: #14a6ef;
}
.side-mobile-menu > ul.mobile-modules-menu-list > li > ul > li > a:hover:before {
  margin-right: 12px;
}
.side-mobile-menu > ul.mobile-modules-menu-list > li > ul > li.active > a {
  color: #14a6ef;
  font-weight: 600;
}
.side-mobile-menu > ul.mobile-modules-menu-list > li.active > a {
  background: #14a6ef;
  border-color: #14a6ef;
  color: #ffffff;
}
.side-mobile-menu > ul.mobile-modules-menu-list > li.active > a .sub-menu-arrow {
  color: #ffffff;
  transform: rotate(180deg);
}
.side-mobile-menu > ul.mobile-modules-menu-list > li.active.hide-sub-menu > a .sub-menu-arrow {
  transform: rotate(0deg);
}
.side-mobile-menu .mobile-back-modules {
  background: transparent;
  border: 0px;
  margin: 15px 10px 5px 10px;
  font-size: 16px;
  font-weight: 600;
  color: #2490ef;
  outline: none !important;
}

.dv-app-theme {
  background: #f8fafb;
  margin: 0px;
}
.dv-app-theme .dv-app-content {
  padding: 0 20px;
  margin-left: 340px;
}
.dv-app-theme .dv-content-overlay {
  position: fixed;
  opacity: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(34, 41, 47, 0.5);
  cursor: pointer;
  transition: all 0.7s, z-index 0s;
  z-index: -1;
}
.dv-app-theme.show-overlay .dv-content-overlay {
  z-index: 10;
  opacity: 1;
}
.dv-app-theme.show-overlay .dv-navbar-overlay {
  z-index: 10 !important;
}

body.hide-main-menu .side-menu {
  width: 80px;
}
body.hide-main-menu .dv-app-theme .dv-app-content {
  margin-left: 80px;
}
body.hide-main-menu.frappe-rtl .dv-app-theme .dv-app-content {
  margin-left: 0 !important;
  margin-right: 80px !important;
}

body.dv-Green-style .side-menu .side-menu-items > ul.dropdown-list > li > a {
  background: rgba(248, 248, 248, 0.4509803922);
  border: 1px solid #d1d3d8;
}
body.dv-Green-style .text-primary {
  color: #43a047 !important;
}
body.dv-Green-style .side-menu .side-menu-icons > ul > li > a:hover {
  border-color: #43a047;
  color: #43a047;
}
body.dv-Green-style .side-menu .side-menu-icons > ul > li.active > a {
  background: #43a047;
  color: #ffffff;
}
body.dv-Green-style .side-menu .side-menu-items > ul.dropdown-list > li > a:hover .sub-menu-arrow {
  color: #43a047;
}
body.dv-Green-style .side-menu .side-menu-items > ul.dropdown-list > li > a:hover {
  border-color: #43a047;
  color: #43a047;
}
body.dv-Green-style .side-menu .side-menu-items > ul.dropdown-list > li.active > a {
  background: #43a047;
  border-color: #43a047;
  color: #ffffff;
}
body.dv-Green-style .side-menu .side-menu-items > ul.dropdown-list > li.active > a .sub-menu-arrow {
  color: #ffffff;
}
body.dv-Green-style .side-menu .side-menu-icons.menu-icons-with-label > ul > li.active > a > span {
  color: #43a047;
}
body.dv-Green-style .datatable .dt-cell--focus .dt-cell__content {
  border-color: #43a047;
}
body.dv-Green-style .widget.shortcut-widget-box:hover svg.icon-xs {
  stroke: #43a047 !important;
}
body.dv-Green-style .side-menu .side-menu-items > ul.dropdown-list > li > ul > li > a:hover {
  color: #43a047;
}
body.dv-Green-style .side-menu .side-menu-items > ul.dropdown-list > li > ul > li.active > a {
  color: #43a047;
}
body.dv-Green-style .btn.btn-primary, body.dv-Green-style div#driver-popover-item .driver-popover-footer button.btn-primary, body.dv-Green-style div#driver-popover-item .driver-popover-footer button.driver-next-btn {
  background-color: #43a047;
  --icon-stroke: currentColor;
  --icon-fill-bg: $green_color_style;
}
body.dv-Green-style .btn-primary:not(:disabled):not(.disabled):active, body.dv-Green-style div#driver-popover-item .driver-popover-footer button.driver-next-btn:not(:disabled):not(.disabled):active, body.dv-Green-style .btn-primary:not(:disabled):not(.disabled).active, body.dv-Green-style div#driver-popover-item .driver-popover-footer button.driver-next-btn:not(:disabled):not(.disabled).active, body.dv-Green-style .show > .btn-primary.dropdown-toggle, body.dv-Green-style div#driver-popover-item .driver-popover-footer .show > button.dropdown-toggle.driver-next-btn {
  background-color: #2a7e2e;
  border-color: #2a7e2e;
}
body.dv-Green-style input[type=checkbox]:checked {
  background-color: #43a047;
  background-image: url("data:image/svg+xml, <svg viewBox='0 0 8 7' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M1 4.00001L2.66667 5.80001L7 1.20001' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/></svg>");
  background-size: 57%, 100%;
}
body.dv-Green-style .form-control:focus {
  border-color: #43a047 !important;
}
body.dv-Green-style .list-sidebar .sidebar-action {
  color: #43a047;
}
body.dv-Green-style .btn-primary-light {
  color: #1f272e;
  background-color: #dff0e0;
  border-color: #dff0e0;
  color: #2a7e2e;
}
body.dv-Green-style .notifications-list .notification-item-tabs .notifications-category.active {
  border-bottom: 1px solid #43a047;
}
body.dv-Green-style .form-tabs-list .form-tabs .nav-item .nav-link.active {
  border-bottom: 1px solid #43a047;
}
body.dv-Green-style button.btn.btn-default.btn-sm.btn-paging.btn-info {
  background: #43a047 !important;
  border-color: #43a047 !important;
}
body.dv-Green-style .dv-navbar .dv-nav-right > ul > li.dropdown-user > a .dv-user-info span.user-name {
  color: #43a047;
}
body.dv-Green-style .dv-navbar .dv-nav-left .btn-open-modules {
  color: #2a7e2e;
}
body.dv-Green-style .dv-navbar .dv-nav-left .btn-open-modules:hover {
  color: #43a047;
}
body.dv-Green-style .ql-snow.ql-toolbar button:hover, body.dv-Green-style .ql-snow .ql-toolbar button:hover, body.dv-Green-style .ql-snow.ql-toolbar button:focus, body.dv-Green-style .ql-snow .ql-toolbar button:focus, body.dv-Green-style .ql-snow.ql-toolbar button.ql-active, body.dv-Green-style .ql-snow .ql-toolbar button.ql-active, body.dv-Green-style .ql-snow.ql-toolbar .ql-picker-label:hover, body.dv-Green-style .ql-snow .ql-toolbar .ql-picker-label:hover, body.dv-Green-style .ql-snow.ql-toolbar .ql-picker-label.ql-active, body.dv-Green-style .ql-snow .ql-toolbar .ql-picker-label.ql-active, body.dv-Green-style .ql-snow.ql-toolbar .ql-picker-item:hover, body.dv-Green-style .ql-snow .ql-toolbar .ql-picker-item:hover, body.dv-Green-style .ql-snow.ql-toolbar .ql-picker-item.ql-selected, body.dv-Green-style .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: #43a047;
}
body.dv-Green-style .dv-navbar .dv-nav-right > ul > li.dropdown-user .dropdown-menu .dropdown-item:hover, body.dv-Green-style .dv-navbar .dv-nav-right > ul > li.dropdown-user .dropdown-menu .dropdown-item:focus {
  color: #43a047 !important;
  background-color: #eeeeee;
}
body.dv-Green-style .dv-navbar .dv-nav-right > ul > li.dropdown-user .dropdown-menu .dropdown-item.active, body.dv-Green-style .dv-navbar .dv-nav-right > ul > li.dropdown-user .dropdown-menu .dropdown-item:active {
  color: #ffffff !important;
  background-color: #43a047;
}
body.dv-Green-style .dv-navbar .dv-nav-right > ul > li.dropdown-language .dropdown-menu .dropdown-item:hover, body.dv-Green-style .dv-navbar .dv-nav-right > ul > li.dropdown-language .dropdown-menu .dropdown-item:focus {
  color: #43a047 !important;
  background-color: #eeeeee;
}
body.dv-Green-style .dv-navbar .dv-nav-right > ul > li.dropdown-language .dropdown-menu .dropdown-item.active, body.dv-Green-style .dv-navbar .dv-nav-right > ul > li.dropdown-language .dropdown-menu .dropdown-item:active {
  color: #ffffff !important;
  background-color: #43a047;
}
body.dv-Green-style .dv-navbar .dv-nav-right > ul > li.dropdown-full-screen > button:hover, body.dv-Green-style .dv-navbar .dv-nav-right > ul > li.dropdown-files > button:hover, body.dv-Green-style .dv-navbar .dv-nav-right > ul > li.dropdown-search > button:hover {
  color: #43a047 !important;
}
body.dv-Green-style .dv-navbar .dv-nav-right > ul > li.dv-dropdown-notifications > a:hover {
  color: #43a047 !important;
}
body.dv-Green-style .side-menu .side-menu-icons.menu-icons-with-label > ul > li > a:hover > div {
  border-color: #43a047 !important;
  color: #43a047 !important;
}
body.dv-Green-style .side-menu .side-menu-icons.menu-icons-with-label > ul > li.active > a > div {
  background: #43a047 !important;
  color: #ffffff !important;
}
body.dv-Green-style mark, body.dv-Green-style .mark {
  padding: 0;
  background-color: #43a047;
  color: #ffffff;
}
body.dv-Green-style .widget.shortcut-widget-box:hover .widget-title {
  color: #43a047 !important;
}
body.dv-Green-style .side-menu .modules-menu .modules-menu-list a {
  color: #666666;
}
body.dv-Green-style .side-menu .modules-menu .modules-menu-list a:hover {
  border-color: #e6e6e6;
  color: #43a047;
}
body.dv-Green-style .side-menu .modules-menu .modules-menu-list a.active {
  color: #43a047;
}
body.dv-Green-style .btn-toggle-main-menu {
  background: #43a047;
}
body.dv-Green-style .btn-toggle-main-menu:hover {
  background: #2a7e2e;
}
body.dv-Green-style.dv-dark-style .btn-primary-light {
  color: #ffffff;
  background-color: #43a047;
  border-color: #43a047;
}
body.dv-Green-style.dv-dark-style .side-menu .side-menu-icons.menu-icons-with-label > ul > li > a > div {
  color: #dff0e0;
}
body.dv-Green-style.dv-dark-style .form-tabs-list .form-tabs .nav-item .nav-link.active {
  color: #43a047;
}
body.dv-Green-style.dv-dark-style .side-menu .modules-menu .modules-menu-list a {
  color: #dff0e0;
}
body.dv-Green-style.dv-dark-style .side-menu .modules-menu .modules-menu-list a:hover {
  border-color: #43a047;
  color: #43a047;
}
body.dv-Green-style.dv-dark-style .side-menu .modules-menu .modules-menu-list a.active {
  color: #43a047;
}
body.dv-Green-style.dv-dark-style .side-menu .side-menu-items > ul.dropdown-list > li > a:hover {
  border-color: #43a047;
  color: #43a047;
}
body.dv-Green-style.dv-dark-style .side-menu .side-menu-items > ul.dropdown-list > li.active > a {
  background: #43a047;
  border-color: #43a047;
  color: #ffffff;
}
body.dv-Green-style.dv-dark-style .dv-navbar .dv-nav-right > ul > li.dropdown-language .dropdown-menu .dropdown-item:hover, body.dv-Green-style.dv-dark-style .dv-navbar .dv-nav-right > ul > li.dropdown-language .dropdown-menu .dropdown-item:focus {
  background-color: #2b3138;
}
body.dv-Green-style.dv-dark-style .side-menu .side-menu-icons > ul > li.active > a {
  color: #ffffff;
}

body.dv-Red-style .side-menu .side-menu-items > ul.dropdown-list > li > a {
  background: rgba(248, 248, 248, 0.4509803922);
  border: 1px solid #d1d3d8;
}
body.dv-Red-style .text-primary {
  color: #e53935 !important;
}
body.dv-Red-style .side-menu .side-menu-icons > ul > li > a:hover {
  border-color: #e53935;
  color: #e53935;
}
body.dv-Red-style .side-menu .side-menu-icons > ul > li.active > a {
  background: #e53935;
  color: #ffffff;
}
body.dv-Red-style .side-menu .side-menu-items > ul.dropdown-list > li > a:hover .sub-menu-arrow {
  color: #e53935;
}
body.dv-Red-style .side-menu .side-menu-items > ul.dropdown-list > li > a:hover {
  border-color: #e53935;
  color: #e53935;
}
body.dv-Red-style .side-menu .side-menu-items > ul.dropdown-list > li.active > a {
  background: #e53935;
  border-color: #e53935;
  color: #ffffff;
}
body.dv-Red-style .side-menu .side-menu-items > ul.dropdown-list > li.active > a .sub-menu-arrow {
  color: #ffffff;
}
body.dv-Red-style .side-menu .side-menu-icons.menu-icons-with-label > ul > li.active > a > span {
  color: #e53935;
}
body.dv-Red-style .datatable .dt-cell--focus .dt-cell__content {
  border-color: #e53935;
}
body.dv-Red-style .widget.shortcut-widget-box:hover svg.icon-xs {
  stroke: #e53935 !important;
}
body.dv-Red-style .side-menu .side-menu-items > ul.dropdown-list > li > ul > li > a:hover {
  color: #e53935;
}
body.dv-Red-style .side-menu .side-menu-items > ul.dropdown-list > li > ul > li.active > a {
  color: #e53935;
}
body.dv-Red-style .btn.btn-primary, body.dv-Red-style div#driver-popover-item .driver-popover-footer button.btn-primary, body.dv-Red-style div#driver-popover-item .driver-popover-footer button.driver-next-btn {
  background-color: #e53935;
  --icon-stroke: currentColor;
  --icon-fill-bg: $red_color_style;
}
body.dv-Red-style .btn-primary:not(:disabled):not(.disabled):active, body.dv-Red-style div#driver-popover-item .driver-popover-footer button.driver-next-btn:not(:disabled):not(.disabled):active, body.dv-Red-style .btn-primary:not(:disabled):not(.disabled).active, body.dv-Red-style div#driver-popover-item .driver-popover-footer button.driver-next-btn:not(:disabled):not(.disabled).active, body.dv-Red-style .show > .btn-primary.dropdown-toggle, body.dv-Red-style div#driver-popover-item .driver-popover-footer .show > button.dropdown-toggle.driver-next-btn {
  background-color: #be2724;
  border-color: #be2724;
}
body.dv-Red-style input[type=checkbox]:checked {
  background-color: #e53935;
  background-image: url("data:image/svg+xml, <svg viewBox='0 0 8 7' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M1 4.00001L2.66667 5.80001L7 1.20001' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/></svg>");
  background-size: 57%, 100%;
}
body.dv-Red-style .form-control:focus {
  border-color: #e53935 !important;
}
body.dv-Red-style .list-sidebar .sidebar-action {
  color: #e53935;
}
body.dv-Red-style .btn-primary-light {
  color: #1f272e;
  background-color: #ffd7d6;
  border-color: #ffd7d6;
  color: #be2724;
}
body.dv-Red-style .notifications-list .notification-item-tabs .notifications-category.active {
  border-bottom: 1px solid #e53935;
}
body.dv-Red-style .form-tabs-list .form-tabs .nav-item .nav-link.active {
  border-bottom: 1px solid #e53935;
}
body.dv-Red-style button.btn.btn-default.btn-sm.btn-paging.btn-info {
  background: #e53935 !important;
  border-color: #e53935 !important;
}
body.dv-Red-style .dv-navbar .dv-nav-right > ul > li.dropdown-user > a .dv-user-info span.user-name {
  color: #e53935;
}
body.dv-Red-style .dv-navbar .dv-nav-left .btn-open-modules {
  color: #be2724;
}
body.dv-Red-style .dv-navbar .dv-nav-left .btn-open-modules:hover {
  color: #e53935;
}
body.dv-Red-style .ql-snow.ql-toolbar button:hover, body.dv-Red-style .ql-snow .ql-toolbar button:hover, body.dv-Red-style .ql-snow.ql-toolbar button:focus, body.dv-Red-style .ql-snow .ql-toolbar button:focus, body.dv-Red-style .ql-snow.ql-toolbar button.ql-active, body.dv-Red-style .ql-snow .ql-toolbar button.ql-active, body.dv-Red-style .ql-snow.ql-toolbar .ql-picker-label:hover, body.dv-Red-style .ql-snow .ql-toolbar .ql-picker-label:hover, body.dv-Red-style .ql-snow.ql-toolbar .ql-picker-label.ql-active, body.dv-Red-style .ql-snow .ql-toolbar .ql-picker-label.ql-active, body.dv-Red-style .ql-snow.ql-toolbar .ql-picker-item:hover, body.dv-Red-style .ql-snow .ql-toolbar .ql-picker-item:hover, body.dv-Red-style .ql-snow.ql-toolbar .ql-picker-item.ql-selected, body.dv-Red-style .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: #e53935;
}
body.dv-Red-style .dv-navbar .dv-nav-right > ul > li.dropdown-user .dropdown-menu .dropdown-item:hover, body.dv-Red-style .dv-navbar .dv-nav-right > ul > li.dropdown-user .dropdown-menu .dropdown-item:focus {
  color: #e53935 !important;
  background-color: #eeeeee;
}
body.dv-Red-style .dv-navbar .dv-nav-right > ul > li.dropdown-user .dropdown-menu .dropdown-item.active, body.dv-Red-style .dv-navbar .dv-nav-right > ul > li.dropdown-user .dropdown-menu .dropdown-item:active {
  color: #ffffff !important;
  background-color: #e53935;
}
body.dv-Red-style .dv-navbar .dv-nav-right > ul > li.dropdown-language .dropdown-menu .dropdown-item:hover, body.dv-Red-style .dv-navbar .dv-nav-right > ul > li.dropdown-language .dropdown-menu .dropdown-item:focus {
  color: #e53935 !important;
  background-color: #eeeeee;
}
body.dv-Red-style .dv-navbar .dv-nav-right > ul > li.dropdown-language .dropdown-menu .dropdown-item.active, body.dv-Red-style .dv-navbar .dv-nav-right > ul > li.dropdown-language .dropdown-menu .dropdown-item:active {
  color: #ffffff !important;
  background-color: #e53935;
}
body.dv-Red-style .dv-navbar .dv-nav-right > ul > li.dropdown-full-screen > button:hover, body.dv-Red-style .dv-navbar .dv-nav-right > ul > li.dropdown-files > button:hover, body.dv-Red-style .dv-navbar .dv-nav-right > ul > li.dropdown-search > button:hover {
  color: #e53935 !important;
}
body.dv-Red-style .dv-navbar .dv-nav-right > ul > li.dv-dropdown-notifications > a:hover {
  color: #e53935 !important;
}
body.dv-Red-style .side-menu .side-menu-icons.menu-icons-with-label > ul > li > a:hover > div {
  border-color: #e53935 !important;
  color: #e53935 !important;
}
body.dv-Red-style .side-menu .side-menu-icons.menu-icons-with-label > ul > li.active > a > div {
  background: #e53935 !important;
  color: #ffffff !important;
}
body.dv-Red-style mark, body.dv-Red-style .mark {
  padding: 0;
  background-color: #e53935;
  color: #ffffff;
}
body.dv-Red-style .widget.shortcut-widget-box:hover .widget-title {
  color: #e53935 !important;
}
body.dv-Red-style .side-menu .modules-menu .modules-menu-list a {
  color: #666666;
}
body.dv-Red-style .side-menu .modules-menu .modules-menu-list a:hover {
  border-color: #e6e6e6;
  color: #e53935;
}
body.dv-Red-style .side-menu .modules-menu .modules-menu-list a.active {
  color: #e53935;
}
body.dv-Red-style .btn-toggle-main-menu {
  background: #e53935;
}
body.dv-Red-style .btn-toggle-main-menu:hover {
  background: #be2724;
}
body.dv-Red-style.dv-dark-style .btn-primary-light {
  color: #ffffff;
  background-color: #e53935;
  border-color: #e53935;
}
body.dv-Red-style.dv-dark-style .side-menu .side-menu-icons.menu-icons-with-label > ul > li > a > div {
  color: #ffd7d6;
}
body.dv-Red-style.dv-dark-style .form-tabs-list .form-tabs .nav-item .nav-link.active {
  color: #e53935;
}
body.dv-Red-style.dv-dark-style .side-menu .modules-menu .modules-menu-list a {
  color: #ffd7d6;
}
body.dv-Red-style.dv-dark-style .side-menu .modules-menu .modules-menu-list a:hover {
  border-color: #e53935;
  color: #e53935;
}
body.dv-Red-style.dv-dark-style .side-menu .modules-menu .modules-menu-list a.active {
  color: #e53935;
}
body.dv-Red-style.dv-dark-style .side-menu .side-menu-items > ul.dropdown-list > li > a:hover {
  border-color: #e53935;
  color: #e53935;
}
body.dv-Red-style.dv-dark-style .side-menu .side-menu-items > ul.dropdown-list > li.active > a {
  background: #e53935;
  border-color: #e53935;
  color: #ffffff;
}
body.dv-Red-style.dv-dark-style .dv-navbar .dv-nav-right > ul > li.dropdown-language .dropdown-menu .dropdown-item:hover, body.dv-Red-style.dv-dark-style .dv-navbar .dv-nav-right > ul > li.dropdown-language .dropdown-menu .dropdown-item:focus {
  background-color: #2b3138;
}
body.dv-Red-style.dv-dark-style .side-menu .side-menu-icons > ul > li.active > a {
  color: #ffffff;
}

body.dv-Orange-style .side-menu .side-menu-items > ul.dropdown-list > li > a {
  background: rgba(248, 248, 248, 0.4509803922);
  border: 1px solid #d1d3d8;
}
body.dv-Orange-style .text-primary {
  color: #fb8c00 !important;
}
body.dv-Orange-style .side-menu .side-menu-icons > ul > li > a:hover {
  border-color: #fb8c00;
  color: #fb8c00;
}
body.dv-Orange-style .side-menu .side-menu-icons > ul > li.active > a {
  background: #fb8c00;
  color: #ffffff;
}
body.dv-Orange-style .side-menu .side-menu-items > ul.dropdown-list > li > a:hover .sub-menu-arrow {
  color: #fb8c00;
}
body.dv-Orange-style .side-menu .side-menu-items > ul.dropdown-list > li > a:hover {
  border-color: #fb8c00;
  color: #fb8c00;
}
body.dv-Orange-style .side-menu .side-menu-items > ul.dropdown-list > li.active > a {
  background: #fb8c00;
  border-color: #fb8c00;
  color: #ffffff;
}
body.dv-Orange-style .side-menu .side-menu-items > ul.dropdown-list > li.active > a .sub-menu-arrow {
  color: #ffffff;
}
body.dv-Orange-style .side-menu .side-menu-icons.menu-icons-with-label > ul > li.active > a > span {
  color: #fb8c00;
}
body.dv-Orange-style .datatable .dt-cell--focus .dt-cell__content {
  border-color: #fb8c00;
}
body.dv-Orange-style .widget.shortcut-widget-box:hover svg.icon-xs {
  stroke: #fb8c00 !important;
}
body.dv-Orange-style .side-menu .side-menu-items > ul.dropdown-list > li > ul > li > a:hover {
  color: #fb8c00;
}
body.dv-Orange-style .side-menu .side-menu-items > ul.dropdown-list > li > ul > li.active > a {
  color: #fb8c00;
}
body.dv-Orange-style .btn.btn-primary, body.dv-Orange-style div#driver-popover-item .driver-popover-footer button.btn-primary, body.dv-Orange-style div#driver-popover-item .driver-popover-footer button.driver-next-btn {
  background-color: #fb8c00;
  --icon-stroke: currentColor;
  --icon-fill-bg: $orange_color_style;
}
body.dv-Orange-style .btn-primary:not(:disabled):not(.disabled):active, body.dv-Orange-style div#driver-popover-item .driver-popover-footer button.driver-next-btn:not(:disabled):not(.disabled):active, body.dv-Orange-style .btn-primary:not(:disabled):not(.disabled).active, body.dv-Orange-style div#driver-popover-item .driver-popover-footer button.driver-next-btn:not(:disabled):not(.disabled).active, body.dv-Orange-style .show > .btn-primary.dropdown-toggle, body.dv-Orange-style div#driver-popover-item .driver-popover-footer .show > button.dropdown-toggle.driver-next-btn {
  background-color: #d07706;
  border-color: #d07706;
}
body.dv-Orange-style input[type=checkbox]:checked {
  background-color: #fb8c00;
  background-image: url("data:image/svg+xml, <svg viewBox='0 0 8 7' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M1 4.00001L2.66667 5.80001L7 1.20001' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/></svg>");
  background-size: 57%, 100%;
}
body.dv-Orange-style .form-control:focus {
  border-color: #fb8c00 !important;
}
body.dv-Orange-style .list-sidebar .sidebar-action {
  color: #fb8c00;
}
body.dv-Orange-style .btn-primary-light {
  color: #1f272e;
  background-color: #ffe8c8;
  border-color: #ffe8c8;
  color: #d07706;
}
body.dv-Orange-style .notifications-list .notification-item-tabs .notifications-category.active {
  border-bottom: 1px solid #fb8c00;
}
body.dv-Orange-style .form-tabs-list .form-tabs .nav-item .nav-link.active {
  border-bottom: 1px solid #fb8c00;
}
body.dv-Orange-style button.btn.btn-default.btn-sm.btn-paging.btn-info {
  background: #fb8c00 !important;
  border-color: #fb8c00 !important;
}
body.dv-Orange-style .dv-navbar .dv-nav-right > ul > li.dropdown-user > a .dv-user-info span.user-name {
  color: #fb8c00;
}
body.dv-Orange-style .dv-navbar .dv-nav-left .btn-open-modules {
  color: #d07706;
}
body.dv-Orange-style .dv-navbar .dv-nav-left .btn-open-modules:hover {
  color: #fb8c00;
}
body.dv-Orange-style .ql-snow.ql-toolbar button:hover, body.dv-Orange-style .ql-snow .ql-toolbar button:hover, body.dv-Orange-style .ql-snow.ql-toolbar button:focus, body.dv-Orange-style .ql-snow .ql-toolbar button:focus, body.dv-Orange-style .ql-snow.ql-toolbar button.ql-active, body.dv-Orange-style .ql-snow .ql-toolbar button.ql-active, body.dv-Orange-style .ql-snow.ql-toolbar .ql-picker-label:hover, body.dv-Orange-style .ql-snow .ql-toolbar .ql-picker-label:hover, body.dv-Orange-style .ql-snow.ql-toolbar .ql-picker-label.ql-active, body.dv-Orange-style .ql-snow .ql-toolbar .ql-picker-label.ql-active, body.dv-Orange-style .ql-snow.ql-toolbar .ql-picker-item:hover, body.dv-Orange-style .ql-snow .ql-toolbar .ql-picker-item:hover, body.dv-Orange-style .ql-snow.ql-toolbar .ql-picker-item.ql-selected, body.dv-Orange-style .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: #fb8c00;
}
body.dv-Orange-style .dv-navbar .dv-nav-right > ul > li.dropdown-user .dropdown-menu .dropdown-item:hover, body.dv-Orange-style .dv-navbar .dv-nav-right > ul > li.dropdown-user .dropdown-menu .dropdown-item:focus {
  color: #fb8c00 !important;
  background-color: #eeeeee;
}
body.dv-Orange-style .dv-navbar .dv-nav-right > ul > li.dropdown-user .dropdown-menu .dropdown-item.active, body.dv-Orange-style .dv-navbar .dv-nav-right > ul > li.dropdown-user .dropdown-menu .dropdown-item:active {
  color: #ffffff !important;
  background-color: #fb8c00;
}
body.dv-Orange-style .dv-navbar .dv-nav-right > ul > li.dropdown-language .dropdown-menu .dropdown-item:hover, body.dv-Orange-style .dv-navbar .dv-nav-right > ul > li.dropdown-language .dropdown-menu .dropdown-item:focus {
  color: #fb8c00 !important;
  background-color: #eeeeee;
}
body.dv-Orange-style .dv-navbar .dv-nav-right > ul > li.dropdown-language .dropdown-menu .dropdown-item.active, body.dv-Orange-style .dv-navbar .dv-nav-right > ul > li.dropdown-language .dropdown-menu .dropdown-item:active {
  color: #ffffff !important;
  background-color: #fb8c00;
}
body.dv-Orange-style .dv-navbar .dv-nav-right > ul > li.dropdown-full-screen > button:hover, body.dv-Orange-style .dv-navbar .dv-nav-right > ul > li.dropdown-files > button:hover, body.dv-Orange-style .dv-navbar .dv-nav-right > ul > li.dropdown-search > button:hover {
  color: #fb8c00 !important;
}
body.dv-Orange-style .dv-navbar .dv-nav-right > ul > li.dv-dropdown-notifications > a:hover {
  color: #fb8c00 !important;
}
body.dv-Orange-style .side-menu .side-menu-icons.menu-icons-with-label > ul > li > a:hover > div {
  border-color: #fb8c00 !important;
  color: #fb8c00 !important;
}
body.dv-Orange-style .side-menu .side-menu-icons.menu-icons-with-label > ul > li.active > a > div {
  background: #fb8c00 !important;
  color: #ffffff !important;
}
body.dv-Orange-style mark, body.dv-Orange-style .mark {
  padding: 0;
  background-color: #fb8c00;
  color: #ffffff;
}
body.dv-Orange-style .widget.shortcut-widget-box:hover .widget-title {
  color: #fb8c00 !important;
}
body.dv-Orange-style .side-menu .modules-menu .modules-menu-list a {
  color: #666666;
}
body.dv-Orange-style .side-menu .modules-menu .modules-menu-list a:hover {
  border-color: #e6e6e6;
  color: #fb8c00;
}
body.dv-Orange-style .side-menu .modules-menu .modules-menu-list a.active {
  color: #fb8c00;
}
body.dv-Orange-style .btn-toggle-main-menu {
  background: #fb8c00;
}
body.dv-Orange-style .btn-toggle-main-menu:hover {
  background: #d07706;
}
body.dv-Orange-style.dv-dark-style .btn-primary-light {
  color: #ffffff;
  background-color: #fb8c00;
  border-color: #fb8c00;
}
body.dv-Orange-style.dv-dark-style .side-menu .side-menu-icons.menu-icons-with-label > ul > li > a > div {
  color: #ffe8c8;
}
body.dv-Orange-style.dv-dark-style .form-tabs-list .form-tabs .nav-item .nav-link.active {
  color: #fb8c00;
}
body.dv-Orange-style.dv-dark-style .side-menu .modules-menu .modules-menu-list a {
  color: #ffe8c8;
}
body.dv-Orange-style.dv-dark-style .side-menu .modules-menu .modules-menu-list a:hover {
  border-color: #fb8c00;
  color: #fb8c00;
}
body.dv-Orange-style.dv-dark-style .side-menu .modules-menu .modules-menu-list a.active {
  color: #fb8c00;
}
body.dv-Orange-style.dv-dark-style .side-menu .side-menu-items > ul.dropdown-list > li > a:hover {
  border-color: #fb8c00;
  color: #fb8c00;
}
body.dv-Orange-style.dv-dark-style .side-menu .side-menu-items > ul.dropdown-list > li.active > a {
  background: #fb8c00;
  border-color: #fb8c00;
  color: #ffffff;
}
body.dv-Orange-style.dv-dark-style .dv-navbar .dv-nav-right > ul > li.dropdown-language .dropdown-menu .dropdown-item:hover, body.dv-Orange-style.dv-dark-style .dv-navbar .dv-nav-right > ul > li.dropdown-language .dropdown-menu .dropdown-item:focus {
  background-color: #2b3138;
}
body.dv-Orange-style.dv-dark-style .side-menu .side-menu-icons > ul > li.active > a {
  color: #ffffff;
}

body.dv-Yellow-style .side-menu .side-menu-items > ul.dropdown-list > li > a {
  background: rgba(248, 248, 248, 0.4509803922);
  border: 1px solid #d1d3d8;
}
body.dv-Yellow-style .text-primary {
  color: #ffca28 !important;
}
body.dv-Yellow-style .side-menu .side-menu-icons > ul > li > a:hover {
  border-color: #ffca28;
  color: #ffca28;
}
body.dv-Yellow-style .side-menu .side-menu-icons > ul > li.active > a {
  background: #ffca28;
  color: #ffffff;
}
body.dv-Yellow-style .side-menu .side-menu-items > ul.dropdown-list > li > a:hover .sub-menu-arrow {
  color: #ffca28;
}
body.dv-Yellow-style .side-menu .side-menu-items > ul.dropdown-list > li > a:hover {
  border-color: #ffca28;
  color: #ffca28;
}
body.dv-Yellow-style .side-menu .side-menu-items > ul.dropdown-list > li.active > a {
  background: #ffca28;
  border-color: #ffca28;
  color: #ffffff;
}
body.dv-Yellow-style .side-menu .side-menu-items > ul.dropdown-list > li.active > a .sub-menu-arrow {
  color: #ffffff;
}
body.dv-Yellow-style .side-menu .side-menu-icons.menu-icons-with-label > ul > li.active > a > span {
  color: #ffca28;
}
body.dv-Yellow-style .datatable .dt-cell--focus .dt-cell__content {
  border-color: #ffca28;
}
body.dv-Yellow-style .widget.shortcut-widget-box:hover svg.icon-xs {
  stroke: #ffca28 !important;
}
body.dv-Yellow-style .side-menu .side-menu-items > ul.dropdown-list > li > ul > li > a:hover {
  color: #ffca28;
}
body.dv-Yellow-style .side-menu .side-menu-items > ul.dropdown-list > li > ul > li.active > a {
  color: #ffca28;
}
body.dv-Yellow-style .btn.btn-primary, body.dv-Yellow-style div#driver-popover-item .driver-popover-footer button.btn-primary, body.dv-Yellow-style div#driver-popover-item .driver-popover-footer button.driver-next-btn {
  background-color: #ffca28;
  --icon-stroke: currentColor;
  --icon-fill-bg: $yellow_color_style;
}
body.dv-Yellow-style .btn-primary:not(:disabled):not(.disabled):active, body.dv-Yellow-style div#driver-popover-item .driver-popover-footer button.driver-next-btn:not(:disabled):not(.disabled):active, body.dv-Yellow-style .btn-primary:not(:disabled):not(.disabled).active, body.dv-Yellow-style div#driver-popover-item .driver-popover-footer button.driver-next-btn:not(:disabled):not(.disabled).active, body.dv-Yellow-style .show > .btn-primary.dropdown-toggle, body.dv-Yellow-style div#driver-popover-item .driver-popover-footer .show > button.dropdown-toggle.driver-next-btn {
  background-color: #deae1b;
  border-color: #deae1b;
}
body.dv-Yellow-style input[type=checkbox]:checked {
  background-color: #ffca28;
  background-image: url("data:image/svg+xml, <svg viewBox='0 0 8 7' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M1 4.00001L2.66667 5.80001L7 1.20001' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/></svg>");
  background-size: 57%, 100%;
}
body.dv-Yellow-style .form-control:focus {
  border-color: #ffca28 !important;
}
body.dv-Yellow-style .list-sidebar .sidebar-action {
  color: #ffca28;
}
body.dv-Yellow-style .btn-primary-light {
  color: #1f272e;
  background-color: #fff2cd;
  border-color: #fff2cd;
  color: #deae1b;
}
body.dv-Yellow-style .notifications-list .notification-item-tabs .notifications-category.active {
  border-bottom: 1px solid #ffca28;
}
body.dv-Yellow-style .form-tabs-list .form-tabs .nav-item .nav-link.active {
  border-bottom: 1px solid #ffca28;
}
body.dv-Yellow-style button.btn.btn-default.btn-sm.btn-paging.btn-info {
  background: #ffca28 !important;
  border-color: #ffca28 !important;
}
body.dv-Yellow-style .dv-navbar .dv-nav-right > ul > li.dropdown-user > a .dv-user-info span.user-name {
  color: #ffca28;
}
body.dv-Yellow-style .dv-navbar .dv-nav-left .btn-open-modules {
  color: #deae1b;
}
body.dv-Yellow-style .dv-navbar .dv-nav-left .btn-open-modules:hover {
  color: #ffca28;
}
body.dv-Yellow-style .ql-snow.ql-toolbar button:hover, body.dv-Yellow-style .ql-snow .ql-toolbar button:hover, body.dv-Yellow-style .ql-snow.ql-toolbar button:focus, body.dv-Yellow-style .ql-snow .ql-toolbar button:focus, body.dv-Yellow-style .ql-snow.ql-toolbar button.ql-active, body.dv-Yellow-style .ql-snow .ql-toolbar button.ql-active, body.dv-Yellow-style .ql-snow.ql-toolbar .ql-picker-label:hover, body.dv-Yellow-style .ql-snow .ql-toolbar .ql-picker-label:hover, body.dv-Yellow-style .ql-snow.ql-toolbar .ql-picker-label.ql-active, body.dv-Yellow-style .ql-snow .ql-toolbar .ql-picker-label.ql-active, body.dv-Yellow-style .ql-snow.ql-toolbar .ql-picker-item:hover, body.dv-Yellow-style .ql-snow .ql-toolbar .ql-picker-item:hover, body.dv-Yellow-style .ql-snow.ql-toolbar .ql-picker-item.ql-selected, body.dv-Yellow-style .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: #ffca28;
}
body.dv-Yellow-style .dv-navbar .dv-nav-right > ul > li.dropdown-user .dropdown-menu .dropdown-item:hover, body.dv-Yellow-style .dv-navbar .dv-nav-right > ul > li.dropdown-user .dropdown-menu .dropdown-item:focus {
  color: #ffca28 !important;
  background-color: #eeeeee;
}
body.dv-Yellow-style .dv-navbar .dv-nav-right > ul > li.dropdown-user .dropdown-menu .dropdown-item.active, body.dv-Yellow-style .dv-navbar .dv-nav-right > ul > li.dropdown-user .dropdown-menu .dropdown-item:active {
  color: #ffffff !important;
  background-color: #ffca28;
}
body.dv-Yellow-style .dv-navbar .dv-nav-right > ul > li.dropdown-language .dropdown-menu .dropdown-item:hover, body.dv-Yellow-style .dv-navbar .dv-nav-right > ul > li.dropdown-language .dropdown-menu .dropdown-item:focus {
  color: #ffca28 !important;
  background-color: #eeeeee;
}
body.dv-Yellow-style .dv-navbar .dv-nav-right > ul > li.dropdown-language .dropdown-menu .dropdown-item.active, body.dv-Yellow-style .dv-navbar .dv-nav-right > ul > li.dropdown-language .dropdown-menu .dropdown-item:active {
  color: #ffffff !important;
  background-color: #ffca28;
}
body.dv-Yellow-style .dv-navbar .dv-nav-right > ul > li.dropdown-full-screen > button:hover, body.dv-Yellow-style .dv-navbar .dv-nav-right > ul > li.dropdown-files > button:hover, body.dv-Yellow-style .dv-navbar .dv-nav-right > ul > li.dropdown-search > button:hover {
  color: #ffca28 !important;
}
body.dv-Yellow-style .dv-navbar .dv-nav-right > ul > li.dv-dropdown-notifications > a:hover {
  color: #ffca28 !important;
}
body.dv-Yellow-style .side-menu .side-menu-icons.menu-icons-with-label > ul > li > a:hover > div {
  border-color: #ffca28 !important;
  color: #ffca28 !important;
}
body.dv-Yellow-style .side-menu .side-menu-icons.menu-icons-with-label > ul > li.active > a > div {
  background: #ffca28 !important;
  color: #ffffff !important;
}
body.dv-Yellow-style mark, body.dv-Yellow-style .mark {
  padding: 0;
  background-color: #ffca28;
  color: #ffffff;
}
body.dv-Yellow-style .widget.shortcut-widget-box:hover .widget-title {
  color: #ffca28 !important;
}
body.dv-Yellow-style .side-menu .modules-menu .modules-menu-list a {
  color: #666666;
}
body.dv-Yellow-style .side-menu .modules-menu .modules-menu-list a:hover {
  border-color: #e6e6e6;
  color: #ffca28;
}
body.dv-Yellow-style .side-menu .modules-menu .modules-menu-list a.active {
  color: #ffca28;
}
body.dv-Yellow-style .btn-toggle-main-menu {
  background: #ffca28;
}
body.dv-Yellow-style .btn-toggle-main-menu:hover {
  background: #deae1b;
}
body.dv-Yellow-style.dv-dark-style .btn-primary-light {
  color: #ffffff;
  background-color: #ffca28;
  border-color: #ffca28;
}
body.dv-Yellow-style.dv-dark-style .side-menu .side-menu-icons.menu-icons-with-label > ul > li > a > div {
  color: #fff2cd;
}
body.dv-Yellow-style.dv-dark-style .form-tabs-list .form-tabs .nav-item .nav-link.active {
  color: #ffca28;
}
body.dv-Yellow-style.dv-dark-style .side-menu .modules-menu .modules-menu-list a {
  color: #fff2cd;
}
body.dv-Yellow-style.dv-dark-style .side-menu .modules-menu .modules-menu-list a:hover {
  border-color: #ffca28;
  color: #ffca28;
}
body.dv-Yellow-style.dv-dark-style .side-menu .modules-menu .modules-menu-list a.active {
  color: #ffca28;
}
body.dv-Yellow-style.dv-dark-style .side-menu .side-menu-items > ul.dropdown-list > li > a:hover {
  border-color: #ffca28;
  color: #ffca28;
}
body.dv-Yellow-style.dv-dark-style .side-menu .side-menu-items > ul.dropdown-list > li.active > a {
  background: #ffca28;
  border-color: #ffca28;
  color: #ffffff;
}
body.dv-Yellow-style.dv-dark-style .dv-navbar .dv-nav-right > ul > li.dropdown-language .dropdown-menu .dropdown-item:hover, body.dv-Yellow-style.dv-dark-style .dv-navbar .dv-nav-right > ul > li.dropdown-language .dropdown-menu .dropdown-item:focus {
  background-color: #2b3138;
}
body.dv-Yellow-style.dv-dark-style .side-menu .side-menu-icons > ul > li.active > a {
  color: #ffffff;
}

body.dv-Violet-style .side-menu .side-menu-items > ul.dropdown-list > li > a {
  background: rgba(248, 248, 248, 0.4509803922);
  border: 1px solid #d1d3d8;
}
body.dv-Violet-style .text-primary {
  color: #ab47bc !important;
}
body.dv-Violet-style .side-menu .side-menu-icons > ul > li > a:hover {
  border-color: #ab47bc;
  color: #ab47bc;
}
body.dv-Violet-style .side-menu .side-menu-icons > ul > li.active > a {
  background: #ab47bc;
  color: #ffffff;
}
body.dv-Violet-style .side-menu .side-menu-items > ul.dropdown-list > li > a:hover .sub-menu-arrow {
  color: #ab47bc;
}
body.dv-Violet-style .side-menu .side-menu-items > ul.dropdown-list > li > a:hover {
  border-color: #ab47bc;
  color: #ab47bc;
}
body.dv-Violet-style .side-menu .side-menu-items > ul.dropdown-list > li.active > a {
  background: #ab47bc;
  border-color: #ab47bc;
  color: #ffffff;
}
body.dv-Violet-style .side-menu .side-menu-items > ul.dropdown-list > li.active > a .sub-menu-arrow {
  color: #ffffff;
}
body.dv-Violet-style .side-menu .side-menu-icons.menu-icons-with-label > ul > li.active > a > span {
  color: #ab47bc;
}
body.dv-Violet-style .datatable .dt-cell--focus .dt-cell__content {
  border-color: #ab47bc;
}
body.dv-Violet-style .widget.shortcut-widget-box:hover svg.icon-xs {
  stroke: #ab47bc !important;
}
body.dv-Violet-style .side-menu .side-menu-items > ul.dropdown-list > li > ul > li > a:hover {
  color: #ab47bc;
}
body.dv-Violet-style .side-menu .side-menu-items > ul.dropdown-list > li > ul > li.active > a {
  color: #ab47bc;
}
body.dv-Violet-style .btn.btn-primary, body.dv-Violet-style div#driver-popover-item .driver-popover-footer button.btn-primary, body.dv-Violet-style div#driver-popover-item .driver-popover-footer button.driver-next-btn {
  background-color: #ab47bc;
  --icon-stroke: currentColor;
  --icon-fill-bg: $violet_color_style;
}
body.dv-Violet-style .btn-primary:not(:disabled):not(.disabled):active, body.dv-Violet-style div#driver-popover-item .driver-popover-footer button.driver-next-btn:not(:disabled):not(.disabled):active, body.dv-Violet-style .btn-primary:not(:disabled):not(.disabled).active, body.dv-Violet-style div#driver-popover-item .driver-popover-footer button.driver-next-btn:not(:disabled):not(.disabled).active, body.dv-Violet-style .show > .btn-primary.dropdown-toggle, body.dv-Violet-style div#driver-popover-item .driver-popover-footer .show > button.dropdown-toggle.driver-next-btn {
  background-color: #773183;
  border-color: #773183;
}
body.dv-Violet-style input[type=checkbox]:checked {
  background-color: #ab47bc;
  background-image: url("data:image/svg+xml, <svg viewBox='0 0 8 7' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M1 4.00001L2.66667 5.80001L7 1.20001' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/></svg>");
  background-size: 57%, 100%;
}
body.dv-Violet-style .form-control:focus {
  border-color: #ab47bc !important;
}
body.dv-Violet-style .list-sidebar .sidebar-action {
  color: #ab47bc;
}
body.dv-Violet-style .btn-primary-light {
  color: #1f272e;
  background-color: #fbe0ff;
  border-color: #fbe0ff;
  color: #773183;
}
body.dv-Violet-style .notifications-list .notification-item-tabs .notifications-category.active {
  border-bottom: 1px solid #ab47bc;
}
body.dv-Violet-style .form-tabs-list .form-tabs .nav-item .nav-link.active {
  border-bottom: 1px solid #ab47bc;
}
body.dv-Violet-style button.btn.btn-default.btn-sm.btn-paging.btn-info {
  background: #ab47bc !important;
  border-color: #ab47bc !important;
}
body.dv-Violet-style .dv-navbar .dv-nav-right > ul > li.dropdown-user > a .dv-user-info span.user-name {
  color: #ab47bc;
}
body.dv-Violet-style .dv-navbar .dv-nav-left .btn-open-modules {
  color: #773183;
}
body.dv-Violet-style .dv-navbar .dv-nav-left .btn-open-modules:hover {
  color: #ab47bc;
}
body.dv-Violet-style .ql-snow.ql-toolbar button:hover, body.dv-Violet-style .ql-snow .ql-toolbar button:hover, body.dv-Violet-style .ql-snow.ql-toolbar button:focus, body.dv-Violet-style .ql-snow .ql-toolbar button:focus, body.dv-Violet-style .ql-snow.ql-toolbar button.ql-active, body.dv-Violet-style .ql-snow .ql-toolbar button.ql-active, body.dv-Violet-style .ql-snow.ql-toolbar .ql-picker-label:hover, body.dv-Violet-style .ql-snow .ql-toolbar .ql-picker-label:hover, body.dv-Violet-style .ql-snow.ql-toolbar .ql-picker-label.ql-active, body.dv-Violet-style .ql-snow .ql-toolbar .ql-picker-label.ql-active, body.dv-Violet-style .ql-snow.ql-toolbar .ql-picker-item:hover, body.dv-Violet-style .ql-snow .ql-toolbar .ql-picker-item:hover, body.dv-Violet-style .ql-snow.ql-toolbar .ql-picker-item.ql-selected, body.dv-Violet-style .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: #ab47bc;
}
body.dv-Violet-style .dv-navbar .dv-nav-right > ul > li.dropdown-user .dropdown-menu .dropdown-item:hover, body.dv-Violet-style .dv-navbar .dv-nav-right > ul > li.dropdown-user .dropdown-menu .dropdown-item:focus {
  color: #ab47bc !important;
  background-color: #eeeeee;
}
body.dv-Violet-style .dv-navbar .dv-nav-right > ul > li.dropdown-user .dropdown-menu .dropdown-item.active, body.dv-Violet-style .dv-navbar .dv-nav-right > ul > li.dropdown-user .dropdown-menu .dropdown-item:active {
  color: #ffffff !important;
  background-color: #ab47bc;
}
body.dv-Violet-style .dv-navbar .dv-nav-right > ul > li.dropdown-language .dropdown-menu .dropdown-item:hover, body.dv-Violet-style .dv-navbar .dv-nav-right > ul > li.dropdown-language .dropdown-menu .dropdown-item:focus {
  color: #ab47bc !important;
  background-color: #eeeeee;
}
body.dv-Violet-style .dv-navbar .dv-nav-right > ul > li.dropdown-language .dropdown-menu .dropdown-item.active, body.dv-Violet-style .dv-navbar .dv-nav-right > ul > li.dropdown-language .dropdown-menu .dropdown-item:active {
  color: #ffffff !important;
  background-color: #ab47bc;
}
body.dv-Violet-style .dv-navbar .dv-nav-right > ul > li.dropdown-full-screen > button:hover, body.dv-Violet-style .dv-navbar .dv-nav-right > ul > li.dropdown-files > button:hover, body.dv-Violet-style .dv-navbar .dv-nav-right > ul > li.dropdown-search > button:hover {
  color: #ab47bc !important;
}
body.dv-Violet-style .dv-navbar .dv-nav-right > ul > li.dv-dropdown-notifications > a:hover {
  color: #ab47bc !important;
}
body.dv-Violet-style .side-menu .side-menu-icons.menu-icons-with-label > ul > li > a:hover > div {
  border-color: #ab47bc !important;
  color: #ab47bc !important;
}
body.dv-Violet-style .side-menu .side-menu-icons.menu-icons-with-label > ul > li.active > a > div {
  background: #ab47bc !important;
  color: #ffffff !important;
}
body.dv-Violet-style mark, body.dv-Violet-style .mark {
  padding: 0;
  background-color: #ab47bc;
  color: #ffffff;
}
body.dv-Violet-style .widget.shortcut-widget-box:hover .widget-title {
  color: #ab47bc !important;
}
body.dv-Violet-style .side-menu .modules-menu .modules-menu-list a {
  color: #666666;
}
body.dv-Violet-style .side-menu .modules-menu .modules-menu-list a:hover {
  border-color: #e6e6e6;
  color: #ab47bc;
}
body.dv-Violet-style .side-menu .modules-menu .modules-menu-list a.active {
  color: #ab47bc;
}
body.dv-Violet-style .btn-toggle-main-menu {
  background: #ab47bc;
}
body.dv-Violet-style .btn-toggle-main-menu:hover {
  background: #773183;
}
body.dv-Violet-style.dv-dark-style .btn-primary-light {
  color: #ffffff;
  background-color: #ab47bc;
  border-color: #ab47bc;
}
body.dv-Violet-style.dv-dark-style .side-menu .side-menu-icons.menu-icons-with-label > ul > li > a > div {
  color: #fbe0ff;
}
body.dv-Violet-style.dv-dark-style .form-tabs-list .form-tabs .nav-item .nav-link.active {
  color: #ab47bc;
}
body.dv-Violet-style.dv-dark-style .side-menu .modules-menu .modules-menu-list a {
  color: #fbe0ff;
}
body.dv-Violet-style.dv-dark-style .side-menu .modules-menu .modules-menu-list a:hover {
  border-color: #ab47bc;
  color: #ab47bc;
}
body.dv-Violet-style.dv-dark-style .side-menu .modules-menu .modules-menu-list a.active {
  color: #ab47bc;
}
body.dv-Violet-style.dv-dark-style .side-menu .side-menu-items > ul.dropdown-list > li > a:hover {
  border-color: #ab47bc;
  color: #ab47bc;
}
body.dv-Violet-style.dv-dark-style .side-menu .side-menu-items > ul.dropdown-list > li.active > a {
  background: #ab47bc;
  border-color: #ab47bc;
  color: #ffffff;
}
body.dv-Violet-style.dv-dark-style .dv-navbar .dv-nav-right > ul > li.dropdown-language .dropdown-menu .dropdown-item:hover, body.dv-Violet-style.dv-dark-style .dv-navbar .dv-nav-right > ul > li.dropdown-language .dropdown-menu .dropdown-item:focus {
  background-color: #2b3138;
}
body.dv-Violet-style.dv-dark-style .side-menu .side-menu-icons > ul > li.active > a {
  color: #ffffff;
}

body.dv-Pink-style .side-menu .side-menu-items > ul.dropdown-list > li > a {
  background: rgba(248, 248, 248, 0.4509803922);
  border: 1px solid #d1d3d8;
}
body.dv-Pink-style .text-primary {
  color: #ec407a !important;
}
body.dv-Pink-style .side-menu .side-menu-icons > ul > li > a:hover {
  border-color: #ec407a;
  color: #ec407a;
}
body.dv-Pink-style .side-menu .side-menu-icons > ul > li.active > a {
  background: #ec407a;
  color: #ffffff;
}
body.dv-Pink-style .side-menu .side-menu-items > ul.dropdown-list > li > a:hover .sub-menu-arrow {
  color: #ec407a;
}
body.dv-Pink-style .side-menu .side-menu-items > ul.dropdown-list > li > a:hover {
  border-color: #ec407a;
  color: #ec407a;
}
body.dv-Pink-style .side-menu .side-menu-items > ul.dropdown-list > li.active > a {
  background: #ec407a;
  border-color: #ec407a;
  color: #ffffff;
}
body.dv-Pink-style .side-menu .side-menu-items > ul.dropdown-list > li.active > a .sub-menu-arrow {
  color: #ffffff;
}
body.dv-Pink-style .side-menu .side-menu-icons.menu-icons-with-label > ul > li.active > a > span {
  color: #ec407a;
}
body.dv-Pink-style .datatable .dt-cell--focus .dt-cell__content {
  border-color: #ec407a;
}
body.dv-Pink-style .widget.shortcut-widget-box:hover svg.icon-xs {
  stroke: #ec407a !important;
}
body.dv-Pink-style .side-menu .side-menu-items > ul.dropdown-list > li > ul > li > a:hover {
  color: #ec407a;
}
body.dv-Pink-style .side-menu .side-menu-items > ul.dropdown-list > li > ul > li.active > a {
  color: #ec407a;
}
body.dv-Pink-style .btn.btn-primary, body.dv-Pink-style div#driver-popover-item .driver-popover-footer button.btn-primary, body.dv-Pink-style div#driver-popover-item .driver-popover-footer button.driver-next-btn {
  background-color: #ec407a;
  --icon-stroke: currentColor;
  --icon-fill-bg: $pink_color_style;
}
body.dv-Pink-style .btn-primary:not(:disabled):not(.disabled):active, body.dv-Pink-style div#driver-popover-item .driver-popover-footer button.driver-next-btn:not(:disabled):not(.disabled):active, body.dv-Pink-style .btn-primary:not(:disabled):not(.disabled).active, body.dv-Pink-style div#driver-popover-item .driver-popover-footer button.driver-next-btn:not(:disabled):not(.disabled).active, body.dv-Pink-style .show > .btn-primary.dropdown-toggle, body.dv-Pink-style div#driver-popover-item .driver-popover-footer .show > button.dropdown-toggle.driver-next-btn {
  background-color: #b92a5a;
  border-color: #b92a5a;
}
body.dv-Pink-style input[type=checkbox]:checked {
  background-color: #ec407a;
  background-image: url("data:image/svg+xml, <svg viewBox='0 0 8 7' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M1 4.00001L2.66667 5.80001L7 1.20001' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/></svg>");
  background-size: 57%, 100%;
}
body.dv-Pink-style .form-control:focus {
  border-color: #ec407a !important;
}
body.dv-Pink-style .list-sidebar .sidebar-action {
  color: #ec407a;
}
body.dv-Pink-style .btn-primary-light {
  color: #1f272e;
  background-color: #ffdde8;
  border-color: #ffdde8;
  color: #b92a5a;
}
body.dv-Pink-style .notifications-list .notification-item-tabs .notifications-category.active {
  border-bottom: 1px solid #ec407a;
}
body.dv-Pink-style .form-tabs-list .form-tabs .nav-item .nav-link.active {
  border-bottom: 1px solid #ec407a;
}
body.dv-Pink-style button.btn.btn-default.btn-sm.btn-paging.btn-info {
  background: #ec407a !important;
  border-color: #ec407a !important;
}
body.dv-Pink-style .dv-navbar .dv-nav-right > ul > li.dropdown-user > a .dv-user-info span.user-name {
  color: #ec407a;
}
body.dv-Pink-style .dv-navbar .dv-nav-left .btn-open-modules {
  color: #b92a5a;
}
body.dv-Pink-style .dv-navbar .dv-nav-left .btn-open-modules:hover {
  color: #ec407a;
}
body.dv-Pink-style .ql-snow.ql-toolbar button:hover, body.dv-Pink-style .ql-snow .ql-toolbar button:hover, body.dv-Pink-style .ql-snow.ql-toolbar button:focus, body.dv-Pink-style .ql-snow .ql-toolbar button:focus, body.dv-Pink-style .ql-snow.ql-toolbar button.ql-active, body.dv-Pink-style .ql-snow .ql-toolbar button.ql-active, body.dv-Pink-style .ql-snow.ql-toolbar .ql-picker-label:hover, body.dv-Pink-style .ql-snow .ql-toolbar .ql-picker-label:hover, body.dv-Pink-style .ql-snow.ql-toolbar .ql-picker-label.ql-active, body.dv-Pink-style .ql-snow .ql-toolbar .ql-picker-label.ql-active, body.dv-Pink-style .ql-snow.ql-toolbar .ql-picker-item:hover, body.dv-Pink-style .ql-snow .ql-toolbar .ql-picker-item:hover, body.dv-Pink-style .ql-snow.ql-toolbar .ql-picker-item.ql-selected, body.dv-Pink-style .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: #ec407a;
}
body.dv-Pink-style .dv-navbar .dv-nav-right > ul > li.dropdown-user .dropdown-menu .dropdown-item:hover, body.dv-Pink-style .dv-navbar .dv-nav-right > ul > li.dropdown-user .dropdown-menu .dropdown-item:focus {
  color: #ec407a !important;
  background-color: #eeeeee;
}
body.dv-Pink-style .dv-navbar .dv-nav-right > ul > li.dropdown-user .dropdown-menu .dropdown-item.active, body.dv-Pink-style .dv-navbar .dv-nav-right > ul > li.dropdown-user .dropdown-menu .dropdown-item:active {
  color: #ffffff !important;
  background-color: #ec407a;
}
body.dv-Pink-style .dv-navbar .dv-nav-right > ul > li.dropdown-language .dropdown-menu .dropdown-item:hover, body.dv-Pink-style .dv-navbar .dv-nav-right > ul > li.dropdown-language .dropdown-menu .dropdown-item:focus {
  color: #ec407a !important;
  background-color: #eeeeee;
}
body.dv-Pink-style .dv-navbar .dv-nav-right > ul > li.dropdown-language .dropdown-menu .dropdown-item.active, body.dv-Pink-style .dv-navbar .dv-nav-right > ul > li.dropdown-language .dropdown-menu .dropdown-item:active {
  color: #ffffff !important;
  background-color: #ec407a;
}
body.dv-Pink-style .dv-navbar .dv-nav-right > ul > li.dropdown-full-screen > button:hover, body.dv-Pink-style .dv-navbar .dv-nav-right > ul > li.dropdown-files > button:hover, body.dv-Pink-style .dv-navbar .dv-nav-right > ul > li.dropdown-search > button:hover {
  color: #ec407a !important;
}
body.dv-Pink-style .dv-navbar .dv-nav-right > ul > li.dv-dropdown-notifications > a:hover {
  color: #ec407a !important;
}
body.dv-Pink-style .side-menu .side-menu-icons.menu-icons-with-label > ul > li > a:hover > div {
  border-color: #ec407a !important;
  color: #ec407a !important;
}
body.dv-Pink-style .side-menu .side-menu-icons.menu-icons-with-label > ul > li.active > a > div {
  background: #ec407a !important;
  color: #ffffff !important;
}
body.dv-Pink-style mark, body.dv-Pink-style .mark {
  padding: 0;
  background-color: #ec407a;
  color: #ffffff;
}
body.dv-Pink-style .widget.shortcut-widget-box:hover .widget-title {
  color: #ec407a !important;
}
body.dv-Pink-style .side-menu .modules-menu .modules-menu-list a {
  color: #666666;
}
body.dv-Pink-style .side-menu .modules-menu .modules-menu-list a:hover {
  border-color: #e6e6e6;
  color: #ec407a;
}
body.dv-Pink-style .side-menu .modules-menu .modules-menu-list a.active {
  color: #ec407a;
}
body.dv-Pink-style .btn-toggle-main-menu {
  background: #ec407a;
}
body.dv-Pink-style .btn-toggle-main-menu:hover {
  background: #b92a5a;
}
body.dv-Pink-style.dv-dark-style .btn-primary-light {
  color: #ffffff;
  background-color: #ec407a;
  border-color: #ec407a;
}
body.dv-Pink-style.dv-dark-style .side-menu .side-menu-icons.menu-icons-with-label > ul > li > a > div {
  color: #ffdde8;
}
body.dv-Pink-style.dv-dark-style .form-tabs-list .form-tabs .nav-item .nav-link.active {
  color: #ec407a;
}
body.dv-Pink-style.dv-dark-style .side-menu .modules-menu .modules-menu-list a {
  color: #ffdde8;
}
body.dv-Pink-style.dv-dark-style .side-menu .modules-menu .modules-menu-list a:hover {
  border-color: #ec407a;
  color: #ec407a;
}
body.dv-Pink-style.dv-dark-style .side-menu .modules-menu .modules-menu-list a.active {
  color: #ec407a;
}
body.dv-Pink-style.dv-dark-style .side-menu .side-menu-items > ul.dropdown-list > li > a:hover {
  border-color: #ec407a;
  color: #ec407a;
}
body.dv-Pink-style.dv-dark-style .side-menu .side-menu-items > ul.dropdown-list > li.active > a {
  background: #ec407a;
  border-color: #ec407a;
  color: #ffffff;
}
body.dv-Pink-style.dv-dark-style .dv-navbar .dv-nav-right > ul > li.dropdown-language .dropdown-menu .dropdown-item:hover, body.dv-Pink-style.dv-dark-style .dv-navbar .dv-nav-right > ul > li.dropdown-language .dropdown-menu .dropdown-item:focus {
  background-color: #2b3138;
}
body.dv-Pink-style.dv-dark-style .side-menu .side-menu-icons > ul > li.active > a {
  color: #ffffff;
}

body.dv-dark-style, html[data-theme=dark] {
  --text-muted: var(--gray-300);
  --text-light: var(--gray-300);
  --text-color: var(--gray-400);
  --border-color: var(--gray-300);
  --fg-color: #242a30;
  --border-color: #161b21;
  --text-on-light-gray: var(--gray-400);
  --heading-color: var(--gray-400);
  background-color: #1b1e23;
}
body.dv-dark-style ::-moz-selection, html[data-theme=dark] ::-moz-selection {
  color: #eee;
  background: var(--gray-500);
}
body.dv-dark-style ::selection, html[data-theme=dark] ::selection {
  color: #eee;
  background: var(--gray-500);
}
body.dv-dark-style .centered.splash, html[data-theme=dark] .centered.splash {
  background: #17191a;
}
body.dv-dark-style .dv-app-theme, html[data-theme=dark] .dv-app-theme {
  background: #1b1e23;
}
body.dv-dark-style .page-container, html[data-theme=dark] .page-container {
  background-color: #1b1e23;
}
body.dv-dark-style .grid-footer, html[data-theme=dark] .grid-footer {
  padding: 0;
  margin: 10px 0px 3px 0px;
  background-color: transparent;
  border-radius: 8px;
}
body.dv-dark-style .btn.btn-secondary:hover, body.dv-dark-style div#driver-popover-item .driver-popover-footer button.btn-secondary:hover, body.dv-dark-style div#driver-popover-item .driver-popover-footer button.driver-prev-btn:hover, body.dv-dark-style .btn.btn-secondary:active, body.dv-dark-style div#driver-popover-item .driver-popover-footer button.btn-secondary:active, body.dv-dark-style div#driver-popover-item .driver-popover-footer button.driver-prev-btn:active, html[data-theme=dark] .btn.btn-secondary:hover, html[data-theme=dark] div#driver-popover-item .driver-popover-footer button.btn-secondary:hover, html[data-theme=dark] div#driver-popover-item .driver-popover-footer button.driver-prev-btn:hover, html[data-theme=dark] .btn.btn-secondary:active, html[data-theme=dark] div#driver-popover-item .driver-popover-footer button.btn-secondary:active, html[data-theme=dark] div#driver-popover-item .driver-popover-footer button.driver-prev-btn:active {
  background-color: #0f1114;
  color: #eee;
}
body.dv-dark-style h1, body.dv-dark-style h2, body.dv-dark-style h3, body.dv-dark-style h4, html[data-theme=dark] h1, html[data-theme=dark] h2, html[data-theme=dark] h3, html[data-theme=dark] h4 {
  color: #d0d0d0;
}
body.dv-dark-style a, html[data-theme=dark] a {
  color: #d0d0d0;
}
body.dv-dark-style .checkbox .label-area, html[data-theme=dark] .checkbox .label-area {
  color: var(--text-muted);
}
body.dv-dark-style .form-control, html[data-theme=dark] .form-control {
  background: #1b1e23;
  border: 1px solid #2f363e;
  color: #eeeeee !important;
}
body.dv-dark-style .form-control:focus, html[data-theme=dark] .form-control:focus {
  background: #24282f !important;
}
body.dv-dark-style .dropdown-menu, html[data-theme=dark] .dropdown-menu {
  border: 1px solid #2f363e !important;
}
body.dv-dark-style .dv-navbar .dv-nav-right > ul > li.dropdown-user .dropdown-menu .dropdown-item, html[data-theme=dark] .dv-navbar .dv-nav-right > ul > li.dropdown-user .dropdown-menu .dropdown-item {
  color: #bbb !important;
}
body.dv-dark-style .dv-navbar .dv-nav-right > ul > li.dropdown-user .dropdown-menu .dropdown-item:hover, body.dv-dark-style .dv-navbar .dv-nav-right > ul > li.dropdown-user .dropdown-menu .dropdown-item:focus, html[data-theme=dark] .dv-navbar .dv-nav-right > ul > li.dropdown-user .dropdown-menu .dropdown-item:hover, html[data-theme=dark] .dv-navbar .dv-nav-right > ul > li.dropdown-user .dropdown-menu .dropdown-item:focus {
  background-color: #2b3138;
}
body.dv-dark-style .btn.btn-default, html[data-theme=dark] .btn.btn-default {
  background: #24292f;
  border: 1px solid #2f363e !important;
  color: #eeeeee;
}
body.dv-dark-style .btn.btn-default:hover, html[data-theme=dark] .btn.btn-default:hover {
  background: #1a1f23 !important;
  border-color: rgba(162, 168, 177, 0.6901960784) !important;
  color: #ffffff;
}
body.dv-dark-style .dv-navbar, html[data-theme=dark] .dv-navbar {
  background: #24282f;
  border-bottom: 1px solid #2f363e;
}
body.dv-dark-style .side-menu, html[data-theme=dark] .side-menu {
  background: #24282f;
  border-right: 1px solid #2f363e;
}
body.dv-dark-style .side-menu .side-menu-icons, html[data-theme=dark] .side-menu .side-menu-icons {
  border-right: 1px solid #2f363e;
}
body.dv-dark-style .std-form-layout > .form-layout > .form-page, html[data-theme=dark] .std-form-layout > .form-layout > .form-page {
  box-shadow: var(--card-shadow);
  background-color: #24292f;
}
body.dv-dark-style .dv-navbar .dv-nav-right > ul > li.dropdown-full-screen > button, body.dv-dark-style .dv-navbar .dv-nav-right > ul > li.dropdown-files > button, body.dv-dark-style .dv-navbar .dv-nav-right > ul > li.dropdown-search > button,
body.dv-dark-style .dv-navbar .dv-nav-right > ul > li.dv-dropdown-notifications > a,
body.dv-dark-style .dv-navbar .dv-nav-right > ul > li.dropdown-language > a.dropdown-lang-link, html[data-theme=dark] .dv-navbar .dv-nav-right > ul > li.dropdown-full-screen > button, html[data-theme=dark] .dv-navbar .dv-nav-right > ul > li.dropdown-files > button, html[data-theme=dark] .dv-navbar .dv-nav-right > ul > li.dropdown-search > button,
html[data-theme=dark] .dv-navbar .dv-nav-right > ul > li.dv-dropdown-notifications > a,
html[data-theme=dark] .dv-navbar .dv-nav-right > ul > li.dropdown-language > a.dropdown-lang-link {
  color: #d0d0d0 !important;
}
body.dv-dark-style .dv-navbar .dv-nav-search .form-control, html[data-theme=dark] .dv-navbar .dv-nav-search .form-control {
  background: #1b1e23;
  border: 1px solid rgba(255, 255, 255, 0.168627451);
}
body.dv-dark-style .dv-navbar .dv-nav-right > ul > li.dropdown-user > a .dv-user-info span.user-status, html[data-theme=dark] .dv-navbar .dv-nav-right > ul > li.dropdown-user > a .dv-user-info span.user-status {
  color: #d0d0d0;
}
body.dv-dark-style .layout-main .btn-toggle-sidebar, html[data-theme=dark] .layout-main .btn-toggle-sidebar {
  background: #24292f;
  color: #c9d0d6;
}
body.dv-dark-style .btn-toggle-main-menu, html[data-theme=dark] .btn-toggle-main-menu {
  background: #24292f;
  border: 1px solid #4c5159;
  color: #bbbbbb;
}
body.dv-dark-style .btn-toggle-main-menu:hover, html[data-theme=dark] .btn-toggle-main-menu:hover {
  background: rgb(22, 27, 33);
  border-color: #6b7784 !important;
  color: #f5f5f5;
}
body.dv-dark-style .side-menu .side-menu-items > ul.dropdown-list > li > a, html[data-theme=dark] .side-menu .side-menu-items > ul.dropdown-list > li > a {
  background: #2c3139;
  border: 1px solid #333a42;
  color: #e5e5e5;
}
body.dv-dark-style .side-menu .side-menu-icons > ul > li > a, html[data-theme=dark] .side-menu .side-menu-icons > ul > li > a {
  color: #c8c8c8;
}
body.dv-dark-style .awesomplete .input-with-feedback, html[data-theme=dark] .awesomplete .input-with-feedback {
  background-color: #1b1e23;
}
body.dv-dark-style .list-row-head, html[data-theme=dark] .list-row-head {
  background: #1b1e23;
  border-bottom: 1px solid #2f363e;
}
body.dv-dark-style .page-form, html[data-theme=dark] .page-form {
  background-color: #24292f;
  border-bottom: 1px solid #2f363e;
}
body.dv-dark-style .side-menu .side-menu-icons > ul > li > a:hover, html[data-theme=dark] .side-menu .side-menu-icons > ul > li > a:hover {
  background: transparent;
}
body.dv-dark-style .side-menu .side-menu-icons > ul > li.active > a, html[data-theme=dark] .side-menu .side-menu-icons > ul > li.active > a {
  box-shadow: 0px 1px 8px -2px rgb(0, 0, 0);
}
body.dv-dark-style .side-menu .side-menu-icons.menu-icons-with-label > ul > li.active > a, html[data-theme=dark] .side-menu .side-menu-icons.menu-icons-with-label > ul > li.active > a {
  box-shadow: none !important;
}
body.dv-dark-style .side-menu .side-menu-icons.menu-icons-with-label > ul > li > a:hover > div, html[data-theme=dark] .side-menu .side-menu-icons.menu-icons-with-label > ul > li > a:hover > div {
  background: transparent;
}
body.dv-dark-style .side-menu .side-menu-items > ul.dropdown-list > li > a:hover, html[data-theme=dark] .side-menu .side-menu-items > ul.dropdown-list > li > a:hover {
  border-color: #14a6ef;
  color: #14a6ef;
}
body.dv-dark-style .side-menu .side-menu-items > ul.dropdown-list > li.active > a, html[data-theme=dark] .side-menu .side-menu-items > ul.dropdown-list > li.active > a {
  background: #14a6ef;
  border-color: #14a6ef;
  color: #ffffff;
}
body.dv-dark-style .side-menu .side-menu-items > ul > li.navigation-divider, html[data-theme=dark] .side-menu .side-menu-items > ul > li.navigation-divider {
  color: #aaa;
}
body.dv-dark-style .bs-popover-bottom > .arrow, body.dv-dark-style .bs-popover-auto[x-placement^=bottom] > .arrow, html[data-theme=dark] .bs-popover-bottom > .arrow, html[data-theme=dark] .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc(-0.4rem - 1px);
}
body.dv-dark-style .popover-body.popover-content, html[data-theme=dark] .popover-body.popover-content {
  border: 1px solid #2d3238;
  border-radius: 8px;
}
body.dv-dark-style .btn.btn-secondary, body.dv-dark-style div#driver-popover-item .driver-popover-footer button.btn-secondary, body.dv-dark-style div#driver-popover-item .driver-popover-footer button.driver-prev-btn, html[data-theme=dark] .btn.btn-secondary, html[data-theme=dark] div#driver-popover-item .driver-popover-footer button.btn-secondary, html[data-theme=dark] div#driver-popover-item .driver-popover-footer button.driver-prev-btn {
  background-color: #1b1e23;
}
body.dv-dark-style .form-links .document-link .document-link-badge, body.dv-dark-style .form-links .document-link .report-link-badge, html[data-theme=dark] .form-links .document-link .document-link-badge, html[data-theme=dark] .form-links .document-link .report-link-badge {
  background-color: #1b1e23;
}
body.dv-dark-style .dt-toast__message, html[data-theme=dark] .dt-toast__message {
  background-color: rgba(117, 117, 117, 0.8);
}
body.dv-dark-style .side-menu .modules-menu, html[data-theme=dark] .side-menu .modules-menu {
  background: #1b1e23;
}
body.dv-dark-style .dv-navbar .dv-nav-right > ul > li.dropdown-language .dropdown-menu .dropdown-item, html[data-theme=dark] .dv-navbar .dv-nav-right > ul > li.dropdown-language .dropdown-menu .dropdown-item {
  color: #bbb !important;
}
body.dv-dark-style .dropdown-item:hover, body.dv-dark-style .dropdown-item:focus, html[data-theme=dark] .dropdown-item:hover, html[data-theme=dark] .dropdown-item:focus {
  background-color: #161a1f;
}
body.dv-dark-style .widget.onboarding-widget-box, html[data-theme=dark] .widget.onboarding-widget-box {
  background-color: #1b1e23;
}
body.dv-dark-style .widget.onboarding-widget-box .widget-body .onboarding-step.active .step-index, body.dv-dark-style .widget.onboarding-widget-box .widget-body .onboarding-step:hover .step-index, html[data-theme=dark] .widget.onboarding-widget-box .widget-body .onboarding-step.active .step-index, html[data-theme=dark] .widget.onboarding-widget-box .widget-body .onboarding-step:hover .step-index {
  background-color: #1b1e23;
  color: #fff;
}
body.dv-dark-style .widget.spacer, html[data-theme=dark] .widget.spacer {
  background: #242a30;
}
body.dv-dark-style .widget.dashboard-widget-box, body.dv-dark-style .widget.shortcut-widget-box, body.dv-dark-style .widget.links-widget-box, html[data-theme=dark] .widget.dashboard-widget-box, html[data-theme=dark] .widget.shortcut-widget-box, html[data-theme=dark] .widget.links-widget-box {
  background: #1b1e23;
  border: 1px solid rgba(221, 221, 221, 0.15);
}
body.dv-dark-style .widget.links-widget-box .widget-head, html[data-theme=dark] .widget.links-widget-box .widget-head {
  border-bottom: 1px solid rgba(221, 221, 221, 0.15);
}
body.dv-dark-style .side-menu .side-menu-icons > ul > li.active > a, html[data-theme=dark] .side-menu .side-menu-icons > ul > li.active > a {
  background: #14a6ef;
  color: #fff;
}
body.dv-dark-style .side-menu .side-menu-icons > ul > li > a:hover, html[data-theme=dark] .side-menu .side-menu-icons > ul > li > a:hover {
  border-color: #14a6ef;
  color: #14a6ef;
}
body.dv-dark-style .side-menu .modules-menu .modules-menu-list a:hover, html[data-theme=dark] .side-menu .modules-menu .modules-menu-list a:hover {
  border-color: #14a6ef;
}

body[data-route^=setup-wizard] .side-menu {
  display: none;
}
body[data-route^=setup-wizard] .dv-app-content {
  margin-left: 0px;
  margin-right: 0px;
}
body[data-route^=setup-wizard] .dv-app-content .page-container {
  max-width: 1024px;
  margin: 0 auto;
}
body[data-route^=setup-wizard] .dv-app-content .page-container .setup-wizard-slide {
  padding: 10px 0px !important;
}

@media (max-width: 1380px) {
  .dv-navbar .dv-nav-search {
    width: 250px;
  }
  .dv-navbar .dv-nav-search .form-control {
    font-size: 13px;
  }
  .dv-navbar .dv-nav-right > ul > li.dropdown-full-screen, .dv-navbar .dv-nav-right > ul > li.dropdown-files {
    display: none !important;
  }
  .form-heatmap .heatmap .chart-container {
    overflow: auto;
  }
  .dv-navbar .dv-nav-right > ul > li.dropdown-user > a .dv-user-info span.user-name {
    font-size: 14px;
  }
}
@media (max-width: 1028px) {
  .side-menu {
    display: none;
  }
  #navbar-breadcrumbs, .side-menu .modules-menu, .side-menu .side-menu-icons, .side-menu .side-menu-items {
    display: none !important;
  }
  .dv-app-theme .dv-app-content {
    margin-left: 0 !important;
    padding-right: 0;
    padding-left: 0;
  }
  .side-menu {
    background: transparent;
    border: 0px;
    width: 250px;
    top: 0px;
  }
  .dv-navbar .dv-nav-left .btn-open-modules {
    display: none;
  }
  .dv-navbar .dv-nav-left .btn-open-mobile-menu {
    display: block;
  }
  .show-in-mobile-lg {
    display: flex;
  }
  button.btn-toggle-main-menu.menu-shown, .layout-main .btn-toggle-sidebar {
    display: none !important;
  }
  .content.page-container {
    padding: 0px 15px !important;
  }
}
/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 800px to 1024px
*/
@media (max-width: 991px) {
  .tooltip.bs-tooltip-top {
    display: none !important;
  }
  .tooltip.show {
    opacity: 0 !important;
  }
}
@media (max-width: 820px) {
  .dv-navbar .dv-nav-search {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    width: 100%;
    margin: 0;
    height: 60px;
    z-index: 55;
    display: none;
  }
  .dv-navbar .dv-nav-search .form-control {
    border: transparent;
    height: 59px;
    padding-left: 40px;
    font-weight: 600;
    font-size: 16px;
  }
  .dv-navbar .dv-nav-search .search-icon {
    top: 18px;
    left: 17px;
    font-size: 17px;
  }
  .dv-navbar .dv-nav-right > ul > li.dropdown-full-screen, .dv-navbar .dv-nav-right > ul > li.dropdown-files {
    display: none;
  }
  .dv-navbar .dv-nav-right > ul > li.dropdown-search {
    display: block;
  }
  .dv-navbar .dv-nav-search .dv-nav-search-close {
    display: block;
  }
  .form-heatmap .heatmap .chart-container {
    width: 100% !important;
    overflow: hidden;
  }
  .layout-main-section-wrapper {
    margin-bottom: 50px;
  }
}
@media (max-width: 600px) {
  .dv-navbar .dv-nav-left .logo {
    margin: 10px 0px 0px 18px;
  }
  .avatar-medium {
    width: 30px;
    height: 30px;
  }
  .dv-navbar .dv-nav-right > ul > li.dropdown-user > a .dv-nav-avatar {
    margin-top: 4px;
  }
  .dv-navbar .dv-nav-right > ul > li.dropdown-user > a .dv-user-info {
    position: relative;
    top: 3px;
    display: none;
  }
  .dv-navbar .dv-nav-right > ul > li.dropdown-user > a .dv-nav-avatar {
    margin-left: 5px;
  }
  .dv-navbar .dv-nav-right > ul > li.dropdown-user > a .dv-user-info span.user-name {
    font-size: 14px;
  }
  .dv-navbar .dv-nav-right > ul > li.dropdown-user > a {
    padding: 8px 0px;
  }
  .dv-navbar .dv-nav-right > ul > li.dropdown-language > a.dropdown-lang-link .dv-lang-flag {
    width: 17px;
    height: 17px;
    margin-top: 7px;
  }
  .dv-navbar .dv-nav-right > ul > li {
    margin: 5px 0px 0px 10px;
  }
  .page-title .sidebar-toggle-btn {
    display: flex !important;
  }
  .show-on-mobile {
    display: inherit;
  }
  .dv-navbar .dv-nav-right > ul > li.dropdown-user .dropdown-menu {
    left: 0 !important;
    right: unset;
  }
  .form-tabs-sticky-down {
    top: 0;
  }
}
@media (max-width: 480px) {
  .page-title .sidebar-toggle-btn {
    position: relative;
    top: 0px;
  }
  .dv-navbar .dv-nav-left .logo {
    max-width: 90px;
  }
  .dv-navbar .dv-nav-left .btn-open-mobile-menu {
    margin: -2px -10px 0px -15px;
    height: 40px;
    width: 30px;
    line-height: 49px;
    font-size: 19px;
  }
  [dir=rtl] body {
    overflow-x: hidden !important;
  }
}
@media (max-width: 380px) {
  .dv-navbar .dv-nav-right > ul > li {
    margin: 3px 5px 0px 0px;
  }
  .dv-navbar .dv-nav-left .logo {
    margin-left: 5px;
  }
  .dv-navbar .dv-nav-right > ul > li.dropdown-user > a .dv-user-info span.user-name {
    font-size: 12px;
  }
  .dv-navbar .dv-nav-right > ul > li.dropdown-user > a .dv-user-info span.user-status {
    font-size: 10px;
  }
}
body.frappe-rtl {
  direction: rtl;
  text-align: right;
  overflow-x: hidden !important;
}
body.frappe-rtl list-inline, body.frappe-rtl .list-unstyled {
  padding-left: 0;
  padding-right: 0;
}
body.frappe-rtl .dv-navbar {
  left: 0px;
  right: 0px;
}
body.frappe-rtl .dv-navbar .dv-nav-left {
  float: right;
  margin: 6px;
}
body.frappe-rtl .dv-navbar .dv-nav-left .btn-open-modules {
  float: right;
  margin: 0px -3px 0px 0px;
}
body.frappe-rtl .dv-navbar .dv-nav-left .navbar-nav {
  padding: 0;
  float: right;
  margin-left: 0px !important;
  margin-right: 0;
}
body.frappe-rtl .dv-navbar .dv-nav-left .logo {
  float: right;
  margin: 3px 18px 0px 0px;
}
body.frappe-rtl .dv-navbar .dv-nav-right {
  float: left;
  margin: 0px 0px 0px 0px;
}
body.frappe-rtl .dv-navbar .dv-nav-left .navbar-breadcrumbs {
  left: auto;
  right: 140px;
}
body.frappe-rtl .side-menu {
  border-right: none;
  border-left: 1px solid #e6e6e6;
}
body.frappe-rtl .dv-navbar .dv-nav-right > ul > li {
  float: left;
  margin: 3px 10px 0px 0px;
}
body.frappe-rtl .dv-navbar .dv-nav-search {
  float: left;
}
body.frappe-rtl .dv-navbar .dv-nav-search .search-icon {
  left: auto;
  right: 15px;
}
body.frappe-rtl .dv-navbar .dv-nav-search .dv-nav-search-close {
  right: auto;
  left: 17px;
}
body.frappe-rtl .dv-navbar .dv-nav-search .form-control {
  padding-left: 10px;
  padding-right: 35px;
}
body.frappe-rtl .dv-navbar .dv-nav-right > ul > li.dropdown-user > a .dv-user-info {
  text-align: left;
}
body.frappe-rtl .dv-navbar .dv-nav-right > ul > li.dropdown-user > a .dv-nav-avatar {
  float: left;
  margin-left: 0;
  margin-right: 10px;
}
body.frappe-rtl .dv-navbar .dv-nav-right > ul > li.dropdown-user > a .avatar-status-online {
  right: inherit;
  left: 5px;
}
body.frappe-rtl .awesomplete > ul > li {
  text-align: right;
}
body.frappe-rtl .dv-app-theme .dv-app-content {
  padding: 0 20px;
  margin-right: 340px;
  margin-left: 0;
}
body.frappe-rtl .side-menu {
  left: inherit;
  right: 0px;
}
body.frappe-rtl .form-links .document-link .document-link-badge .count, body.frappe-rtl .form-links .document-link .report-link-badge .count {
  float: left;
}
body.frappe-rtl .grid-footer .btn, body.frappe-rtl .grid-footer div#driver-popover-item .driver-popover-footer button, body.frappe-rtl div#driver-popover-item .driver-popover-footer .grid-footer button {
  margin-left: 4px;
  margin-right: 0px !important;
}
body.frappe-rtl .side-menu .side-menu-icons {
  border-right: 0px;
  border-left: 1px solid #e6e6e6;
  left: inherit;
  right: 0;
}
body.frappe-rtl .side-menu .side-menu-icons > ul {
  padding: 0px;
}
body.frappe-rtl .side-menu .side-menu-icons > ul > li > a {
  margin: 0px auto;
}
body.frappe-rtl .widget.onboarding-widget-box .widget-body .onboarding-step-preview {
  padding-right: var(--padding-lg);
  padding-left: 0;
  text-align: right;
}
body.frappe-rtl .widget.onboarding-widget-box .widget-body .onboarding-step .step-index {
  margin-right: 0px;
  margin-left: var(--margin-sm);
}
body.frappe-rtl .awesomplete > ul > li, body.frappe-rtl .ellipsis, body.frappe-rtl .new-timeline .document-email-link-container, body.frappe-rtl .widget.shortcut-widget-box .indicator-pill {
  text-align: right;
}
body.frappe-rtl .widget.links-widget-box .link-item .indicator-pill {
  margin-right: 0px;
  margin-left: var(--margin-sm);
}
body.frappe-rtl .side-menu .side-menu-items {
  right: 80px;
  left: inherit;
}
body.frappe-rtl .side-menu .side-menu-items > ul > li.navigation-divider, body.frappe-rtl .side-menu .side-menu-items > ul.dropdown-list > li > a, body.frappe-rtl .side-menu .side-menu-items > ul.dropdown-list > li > ul > li > a {
  text-align: right;
}
body.frappe-rtl .side-menu .side-menu-items > ul.dropdown-list > li > a .sub-menu-arrow {
  float: left;
}
body.frappe-rtl .side-menu .side-menu-items > ul.dropdown-list > li > ul > li > a:before {
  margin: 0px 0px 0px 6px;
}
body.frappe-rtl .side-menu .side-menu-items > ul.dropdown-list > li > ul > li > a:hover:before {
  margin-left: 12px;
  margin-right: 0;
}
body.frappe-rtl .form-section .section-head, body.frappe-rtl .form-dashboard-section .section-head, body.frappe-rtl .form-message {
  text-align: right;
}
body.frappe-rtl .frappe-control[data-fieldtype=Select] .control-input .select-icon, body.frappe-rtl .frappe-control[data-fieldtype=Select].form-group .select-icon {
  top: 6px;
  right: inherit;
  left: 12px;
}
body.frappe-rtl .frappe-control[data-fieldtype=Select] .control-input .select-icon.xs, body.frappe-rtl .frappe-control[data-fieldtype=Select].form-group .select-icon.xs {
  top: 3px;
  right: inherit;
  left: 8px;
}
body.frappe-rtl .tree.with-skeleton, body.frappe-rtl .tree.with-skeleton .tree-node {
  text-align: right;
}
body.frappe-rtl .radio input[type=radio], body.frappe-rtl .radio-inline input[type=radio], body.frappe-rtl .checkbox input[type=checkbox], body.frappe-rtl .checkbox-inline input[type=checkbox] {
  margin-right: -20px !important;
  margin-left: auto;
}
body.frappe-rtl .checkbox-options .radio input[type=radio], body.frappe-rtl .checkbox-options .radio-inline input[type=radio], body.frappe-rtl .checkbox-options .checkbox input[type=checkbox], body.frappe-rtl .checkbox-options .checkbox-inline input[type=checkbox] {
  margin-left: 10px;
}
body.frappe-rtl select.input-xs {
  line-height: 21px;
}
body.frappe-rtl .frappe-control[data-fieldtype=Select] .control-input .placeholder, body.frappe-rtl .frappe-control[data-fieldtype=Select].form-group .placeholder {
  right: 12px;
  left: inherit;
}
body.frappe-rtl .level-left.checkbox-actions {
  margin-right: 15px;
}
body.frappe-rtl .new-timeline .timeline-actions .action-btn .icon {
  margin-left: var(--margin-xs);
  margin-right: 0;
}
body.frappe-rtl .avatar-group.left {
  margin-right: 0;
  margin-left: var(--margin-xs);
}
body.frappe-rtl .indicator-pill::before, body.frappe-rtl .indicator-pill-right::after {
  margin-left: 6px;
  margin-right: 0;
}
body.frappe-rtl .msgprint {
  text-align: right;
}
body.frappe-rtl .link-btn {
  right: auto;
  left: 8px;
  top: 5px !important;
  transform: rotate(-180deg);
}
body.frappe-rtl .checkbox {
  padding-right: 20px;
}
body.frappe-rtl input.grid-row-check.pull-left {
  float: right;
}
body.frappe-rtl #navbar-breadcrumbs a:before {
  margin-right: 0px;
  margin-left: 10px;
  transform: rotate(180deg);
  top: 3px;
}
body.frappe-rtl .indicator:before {
  margin: 0 0px 0 8px;
}
body.frappe-rtl .dropdown-item {
  text-align: right;
}
body.frappe-rtl .chart-container {
  direction: ltr;
}
body.frappe-rtl .page-title .title-area .title-text {
  margin-left: var(--margin-sm);
  margin-right: var(--margin-sm);
}
body.frappe-rtl .form-links .document-link .btn-new, body.frappe-rtl .form-links .document-link .open-notification {
  margin-left: 0;
  margin-right: var(--margin-sm);
}
body.frappe-rtl .form-links .form-link-title {
  text-align: right;
}
body.frappe-rtl .frappe-control {
  text-align: right;
}
body.frappe-rtl .new-timeline {
  padding-right: var(--timeline-left-padding);
  padding-left: 0;
}
body.frappe-rtl .new-timeline .timeline-dot {
  left: inherit;
  right: -8px;
}
body.frappe-rtl .new-timeline .timeline-item .timeline-content {
  margin-left: 0;
  margin-right: var(--timeline-item-left-margin);
  text-align: right;
}
body.frappe-rtl .new-timeline .timeline-item .timeline-badge {
  left: inherit;
  right: calc(-1 * var(--timeline-item-icon-size) / 2);
}
body.frappe-rtl .new-timeline .timeline-actions .action-btn:first-of-type {
  margin-left: 10px;
  margin-right: 10px;
}
body.frappe-rtl .new-timeline:before {
  right: 46px;
}
body.frappe-rtl .form-footer .scroll-to-top {
  left: 0;
  right: inherit;
}
body.frappe-rtl .side-menu .modules-menu .modules-menu-list a {
  padding: 0px 10px 0px 5px;
  text-align: right;
  font-size: 12px;
}
body.frappe-rtl .side-menu .modules-menu .modules-menu-list a i {
  font-size: 17px;
  margin-left: 2px;
  margin-right: 0;
}
body.frappe-rtl .widget.dashboard-widget-box .widget-control {
  left: var(--margin-sm);
  right: inherit;
}
body.frappe-rtl .modal .form-section .form-column:first-child {
  padding-left: 15px;
  padding-right: 0px;
}
body.frappe-rtl .modal .modal-header .modal-actions {
  right: inherit;
  left: 5px;
}
body.frappe-rtl .checkbox .disp-area {
  margin-right: 0px;
  margin-left: 0px;
}
body.frappe-rtl .file-web-link.margin-bottom {
  text-align: right;
}
body.frappe-rtl .file-icon {
  margin-right: 0;
  margin-left: var(--margin-md);
}
body.frappe-rtl .ml-2, body.frappe-rtl .mx-2 {
  margin-left: 0px !important;
  margin-right: 7px !important;
}
body.frappe-rtl .file-actions {
  margin-left: 0;
  margin-right: auto;
}
body.frappe-rtl .file-preview-container {
  text-align: right;
}
body.frappe-rtl .sidebar-menu > li {
  text-align: right;
}
body.frappe-rtl .sidebar-image-actions .dropdown-menu {
  right: inherit !important;
  bottom: auto !important;
  top: inherit !important;
  left: auto !important;
  transform: inherit !important;
}
body.frappe-rtl .side-mobile-menu {
  left: auto;
  right: 0px;
  text-align: right;
}
body.frappe-rtl .side-mobile-menu .mobile-back-modules .fal {
  transform: rotate(180deg);
}
body.frappe-rtl .side-mobile-menu > ul.mobile-modules-menu-list > li > a {
  text-align: right;
}
body.frappe-rtl .side-mobile-menu > ul.mobile-modules-menu-list > li > a .sub-menu-arrow {
  float: left;
}
body.frappe-rtl .side-mobile-menu > ul.mobile-modules-menu-list > li > ul > li > a:before {
  margin: 0px 0px 0px 6px;
}
body.frappe-rtl .dv-navbar .dv-nav-left .btn-open-mobile-menu {
  float: right;
  margin: 0px -15px 0px -10px;
}
body.frappe-rtl .list-row-col {
  margin-left: 0px;
  padding-right: 15px;
}
body.frappe-rtl .list-row, body.frappe-rtl .list-row-head {
  padding: 15px 0px 15px 15px;
}
body.frappe-rtl #alert-container .desk-alert .alert-message-container {
  padding: var(--padding-md);
  padding-right: initial;
  padding-left: var(--padding-2xl);
}
body.frappe-rtl .alert-body {
  text-align: right;
}
body.frappe-rtl input[type=checkbox] {
  margin-right: 0px !important;
  margin-left: var(--checkbox-right-margin) !important;
}
body.frappe-rtl #alert-container .desk-alert .close {
  right: auto;
  left: var(--padding-md);
}
body.frappe-rtl .btn-group > .btn-group:not(:last-child) > .btn, body.frappe-rtl .btn-group > .btn:not(:last-child):not(.dropdown-toggle), body.frappe-rtl div#driver-popover-item .driver-popover-footer .btn-group > .btn-group:not(:last-child) > button, body.frappe-rtl div#driver-popover-item .driver-popover-footer .btn-group > button:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
body.frappe-rtl .btn-group > .btn-group:not(:first-child) > .btn, body.frappe-rtl .btn-group > .btn:not(:first-child), body.frappe-rtl div#driver-popover-item .driver-popover-footer .btn-group > .btn-group:not(:first-child) > button, body.frappe-rtl div#driver-popover-item .driver-popover-footer .btn-group > button:not(:first-child) {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
body.frappe-rtl .btn-group > .btn-group:not(:first-child), body.frappe-rtl .btn-group > .btn:not(:first-child), body.frappe-rtl div#driver-popover-item .driver-popover-footer .btn-group > button:not(:first-child) {
  margin-right: -1px;
  margin-left: 0px;
}
body.frappe-rtl .btn-toggle-main-menu i.far {
  transform: rotate(180deg);
}
body.frappe-rtl .layout-main .btn-toggle-sidebar {
  box-shadow: 2px 0px 5px -2px rgba(0, 0, 0, 0.1882352941);
  margin: 0px 0px 0px 0px;
  left: auto;
  right: 0;
  border-radius: 0px 5px 5px 0px;
}
body.frappe-rtl .layout-main .btn-toggle-sidebar > i {
  transform: rotate(180deg);
}
body.frappe-rtl .layout-main .btn-toggle-sidebar.sidebar-shown > i {
  transform: rotate(0deg);
}
body.frappe-rtl .pull-right {
  float: left;
}
body.frappe-rtl .text-left {
  text-align: right !important;
}
body.frappe-rtl .tree-children {
  padding-right: var(--padding-xl);
  padding-left: 0;
}
body.frappe-rtl .tree-label {
  margin-right: var(--margin-sm);
  margin-left: 0;
}
body.frappe-rtl .nav {
  padding-right: 0;
}
body.frappe-rtl .mark-all-read {
  margin-left: 15px;
  margin-right: 0;
}
body.frappe-rtl .list-subject .level-item:not(.file-select) {
  margin-right: 0;
  margin-left: 8px;
}
body.frappe-rtl .ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
  right: unset;
  left: 0;
}
body.frappe-rtl .widget .widget-head .widget-title svg {
  margin-left: 6px;
  margin-right: 0;
}
body.frappe-rtl .frappe-control[data-fieldtype=Select] .control-input select, body.frappe-rtl .frappe-control[data-fieldtype=Select].form-group select {
  text-align: right !important;
}
@media (max-width: 1028px) {
  body.frappe-rtl .dv-app-theme .dv-app-content {
    margin-right: 0 !important;
    padding-right: 0;
    padding-left: 0;
  }
  body.frappe-rtl .dv-navbar .dv-nav-right > ul > li.dropdown-notifications .notifications-list {
    right: 0px;
    left: auto !important;
    max-height: initial;
    min-width: inherit;
    transform: translate3d(330px, 40px, 0px) !important;
  }
}
@media (max-width: 991px) {
  body.frappe-rtl .layout-side-section .overlay-sidebar {
    transform: translateX(110%);
    left: auto;
    right: 0px;
  }
  body.frappe-rtl .layout-side-section .overlay-sidebar.opened {
    transform: translateX(0);
  }
}
@media (min-width: 768px) {
  body.frappe-rtl .layout-main .form-column.col-sm-12 > form > .input-max-width {
    padding-right: 0px;
    padding-left: 15px;
  }
  body.frappe-rtl .layout-main-section.form-dashboard-section, body.frappe-rtl .layout-main-section.form-section.card-section, body.frappe-rtl .layout-main-section.frappe-card {
    overflow: visible;
  }
}
@media (max-width: 550px) {
  body.frappe-rtl .dv-navbar .dv-nav-right > ul > li.dropdown-notifications .notifications-list {
    transform: translate3d(300px, 40px, 0px) !important;
  }
}
@media (max-width: 500px) {
  body.frappe-rtl .dv-navbar .dv-nav-right > ul > li.dropdown-notifications .notifications-list {
    transform: translate3d(270px, 40px, 0px) !important;
  }
}
@media (max-width: 480px) {
  body.frappe-rtl .dv-navbar .dv-nav-right > ul > li.dropdown-notifications .notifications-list {
    transform: translate3d(250px, 40px, 0px) !important;
  }
}
@media (max-width: 450px) {
  body.frappe-rtl .dv-navbar .dv-nav-right > ul > li.dropdown-notifications .notifications-list {
    transform: translate3d(210px, 40px, 0px) !important;
  }
}
@media (max-width: 420px) {
  body.frappe-rtl .dv-navbar .dv-nav-right > ul > li.dropdown-notifications .notifications-list {
    transform: translate3d(190px, 40px, 0px) !important;
    width: calc(90vw - 30px);
  }
}
@media (max-width: 380px) {
  body.frappe-rtl .dv-navbar .dv-nav-right > ul > li {
    margin: 3px 0px 0px 5px;
  }
  body.frappe-rtl .dv-navbar .dv-nav-left .logo {
    margin-left: 0px;
    margin-right: 5px;
  }
}

@media (min-width: 550px) {
  .widget.ts-shortcut-widget-box .widget-head .widget-title {
    max-width: 120px;
  }
}
.widget.ts-shortcut-widget-box {
  background: #f7f9fe;
}
.widget.ts-shortcut-widget-box .ts-widget-icon {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #f7f9fe;
}
.widget.ts-shortcut-widget-box .widget-title {
  max-width: 200px;
}
.widget.ts-shortcut-widget-box:hover {
  background: #14a6ef;
  border-color: #f7f9fe;
}
.widget.ts-shortcut-widget-box:hover .widget-title {
  color: #ffffff !important;
}

.widget.ts-number-widget-box {
  background: #f7f9fe;
  border: 1px solid #14a6ef !important;
}
.widget.ts-number-widget-box:hover {
  background: #14a6ef;
  border-color: #f7f9fe;
}
.widget.ts-number-widget-box:hover .widget-title {
  color: #ffffff !important;
}

.sticky-top {
  position: fixed;
  width: 100%;
}

#body {
  margin-top: 48px;
}